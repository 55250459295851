const Refund = () => {
  return (
    <div className="our-team-content">
      <div className="header-our-team">
        <div className="container">
          <h1>Refund Policy</h1>
        </div>
      </div>

      <hr className="gray-line" />
      <div className="the-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div className="team-item">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>NOTE FOR RETURN & EXCHANGE:</label>
                    </h4>
                    The items should be unused and unwashed for hygiene reasons.
                    The product should have the original packaging and tags in
                    place. Items without the original tags will not be accepted.
                    Customized products cannot be returned or exchanged
                    Return/Exchange request that are not raised within 3 days of
                    receiving the product would not be accepted. The product
                    would be picked in 5-7 days after the return or exchange
                    request is approved. Products bought during sale are
                    non-refundable Jewellery, Hair Accessory, Travel Accessory,
                    Mending Kit, Brooches, Face Masks, Handkerchiefs, Paintings,
                    Buntings cannot be returned or exchanged
                  </div>
                  <br></br>
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Domestic Orders:</label>
                    </h4>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Cancellation Policy</label>
                    </h4>
                    In case there is an order cancellation, please do so before
                    it is shipped. Shipping charges are non-refundable once the
                    order is dispatched. You can request your order cancellation
                    by sending a mail on support@thewovenwonders.com
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Return Policy</label>
                    </h4>
                    If you have received the product in a bad condition or if
                    the packaging is tampered with, please refuse the package.
                    Mail us at support@thewovenwonders.com mentioning your Order
                    ID. The replacement will be issued to you with no additional
                    cost. Shipping and COD charges are non refundable Raise your
                    return request within 3 days of receiving the shipment If
                    you have to cancel an order, please do so before your order
                    is shipped If you cancel your order before it has been
                    shipped, we will refund the entire amount The shipping
                    charges for sending the products back has to be borne by the
                    customer Once we have received the products a quality check
                    would be done by our team The amount refunded to you would
                    be only for the products not the shipping charges If you do
                    opt for a credit note, you cannot en-cash it later. Incase
                    we do not have pick up service available at your location,
                    you would have to self ship the product. A maximum of 150
                    INR would be given to you as a credit note for reverse
                    shipping. We unfortunately do not have an exchange policy
                    for International customers. If you first opted for an
                    exchange and then would want to return the exchanged product
                    for a refund ,you would have to bear the 150 Rs reverse
                    shipping charges
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>International Orders:</label>
                    </h4>
                    We unfortunately do not have return or exchange policy for
                    our international customers. You can get in touch with us at
                    support@thewovenwonders.com for any queries. If you do wish
                    to return the product, you can ship it back to us at your
                    own expense, we can provide you a refund for the product to
                    your original mode of payment or as a store credit. Once we
                    have received the products a quality check would be done by
                    our team. Please make sure that the original product tag is
                    intact If you have to cancel an order, please do so before
                    your order is shipped and we will refund the entire amount.
                    Once your order is shipped we cannot cancel the order and no
                    refund would be provided.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refund;

import axios from "../../common/axios";
import { keys } from "../../config/keys";
import { BLOGS } from "../constTypes";
import { _dispatchPayload } from "./dispatchPayload";

export const get_blogs = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/blogs`)
    .then((res) => {
      dispatch(_dispatchPayload(BLOGS, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_blogs_by_id = (id,callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/blogs/${id}`)
    .then((res) => {
      console.log(res)
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[0]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { blogAction } from "../../store/actions";

const BlogDetails = () => {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const [data, setdata] = useState<any>();

  const _get_blogs = () =>
    dispatch(
      blogAction.get_blogs_by_id(params.id, {
        onSuccess: (res: any) => setdata(res)
      })
    );

  useEffect(() => {
  
    _get_blogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main ckeditor">
      <div className="container">
        <div className="header-page">
          <h4>{data?.title}</h4>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data?.content }} />
      </div>
    </div>
  );
};

export default BlogDetails;

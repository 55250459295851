import * as actionTypes from "./types";
import axios from "axios";

export const addCartItem = (data) => async (dispatch) => {
  dispatch(_addCartItem(data));
};

export const _addCartItem = (data) => {
  return {
    type: actionTypes.ADD_CART_ITEM,
    data: data
  };
};

export const payment = (objPay) => async (dispatch) => {
  console.log(objPay);
  await axios({
    method: "post",
    url: "http://localhost:5010/api/pay",
    data: objPay
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          var obj = {};
          obj.hash = res.data.params.hash;
          obj.amount = parseFloat(res.data.params.amount);
          obj = res.data.params;
          launchBOLT(obj);
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

function launchBOLT(payParams) {
//  console.log(payParams);
  let bolt = window.bolt;
  let a = payParams;

  sessionStorage.setItem("paymentParams", payParams);
  bolt.launch(
    {
      hash: payParams.hash,
      txnid: payParams.txnid.toString(),
      key: payParams.key,
      amount: payParams.amount,
      firstname: payParams.firstname,
      email: payParams.email,
      phone: payParams.phone,
      productinfo: payParams.productinfo,
      surl: payParams.surl,
      furl: payParams.furl,
      lastname: payParams.lastname,
      //curl: payParams.curl,
      address1: payParams.address1,
      address2: payParams.address2,
      city: payParams.city,
      state: payParams.state,
      country: payParams.country,
      zipcode: payParams.zipcode,
      udf1: payParams.udf1,
      udf2: payParams.udf2,
      udf3: payParams.udf3,
      udf4: payParams.udf4,
      // udf5: payParams.vpc_MerchTxnRef,
      udf5: payParams.udf5,
      pg: payParams.pg
    },
    {
      responseHandler: async function (BOLT) {
      //  console.log(BOLT.response);
        let queryString = "provider=PAYU&";
        Object.entries(BOLT.response).forEach(([key, value]) => {
          queryString += key + "=" + value + "&";
        });
        queryString = queryString.slice(0, -1);
        window.location.href = `http://localhost:3001/shop/paymentreceipt?${queryString}`;
      },
      catchException: function (BOLT) {
        alert(BOLT.message);
      }
    }
  );
}

export const deleteCartItem = (data) => async (dispatch) => {
  dispatch(_deleteCartItem(data));
};

export const _deleteCartItem = (data) => {
  return {
    type: actionTypes.DELETE_CART_ITEM,
    data: data
  };
};

export const emptyCartItem = (data) => async (dispatch) => {
  dispatch(_emptyCartItem([]));
};

export const _emptyCartItem = (data) => {
  return {
    type: actionTypes.EMPTY_CART_ITEM,
    data: data
  };
};

import { keys } from "../../config/keys";
import axios from "../../common/axios";
import { _dispatchPayload } from "./dispatchPayload";
import { GET_CITIES, GET_COUNTRIES, GET_STATES } from "../constTypes";

export type Callbacks = {
  onSuccess?: (val: any) => void;
  onError?: (val: any) => void;
};


export const get_geoLocations = (callback?: Callbacks) => async (dispatch: any) => {
  await axios({
    method: "get",
    url: `${keys.ServicePath}/api/common/get_geolocations`
  }).then((res) => {
    dispatch(_dispatchPayload(GET_COUNTRIES, res.data.rows[0]));
    dispatch(_dispatchPayload(GET_STATES, res.data.rows[1]));
    dispatch(_dispatchPayload(GET_CITIES, res.data.rows[2]));
    if (callback?.onSuccess) callback.onSuccess(res.data);
  }).catch((e) => {
    if (callback?.onError) callback?.onError(e);
  });
};

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { keys } from "../../config/keys";
import "react-image-gallery/styles/css/image-gallery.css";

const Slideshow = () => {
  useEffect(() => {
    // const script = document.createElement("script");
    try {
      // script.src = "../../assets/scripts/functions.js";
      // document.head.appendChild(script);
    } catch (e) {}

    const resizeImgSlideshow = () => {
      const contentImgs = document.querySelectorAll(".content-img");
      const mq1200 = window.matchMedia("(min-width: 1200px)");
      const mq992 = window.matchMedia("(min-width: 992px)");
      const mq768 = window.matchMedia("(min-width: 768px)");

      contentImgs.forEach((img: any) => {
        if (mq1200.matches) {
          img.style.width = img.naturalWidth + "px";
        } else if (mq992.matches) {
          img.style.width = (img.naturalWidth * 70) / 100 + "px";
        } else if (mq768.matches) {
          img.style.width = (img.naturalWidth * 50) / 100 + "px";
        } else {
          img.style.width = (img.naturalWidth * 30) / 100 + "px";
        }
      });
    };

    const handleWindowResize = () => {
      resizeImgSlideshow();
    };

    // Initial resizing
    resizeImgSlideshow();

    // Event listener for resizing
    window.addEventListener("resize", handleWindowResize);

    return () => {
      // Cleanup event listener
      window.removeEventListener("resize", handleWindowResize);
      //document.head.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   const resizeImgSlideshow = () => {
  //     const contentImgs = document.querySelectorAll(".content-img");
  //     const mq1200 = window.matchMedia("(min-width: 1200px)");
  //     const mq992 = window.matchMedia("(min-width: 992px)");
  //     const mq768 = window.matchMedia("(min-width: 768px)");

  //     contentImgs.forEach((img: any) => {
  //       if (mq1200.matches) {
  //         img.style.width = img.naturalWidth + "px";
  //       } else if (mq992.matches) {
  //         img.style.width = (img.naturalWidth * 70) / 100 + "px";
  //       } else if (mq768.matches) {
  //         img.style.width = (img.naturalWidth * 50) / 100 + "px";
  //       } else {
  //         img.style.width = (img.naturalWidth * 30) / 100 + "px";
  //       }
  //     });
  //   };

  //   const handleWindowResize = () => {
  //     resizeImgSlideshow();
  //   };

  //   // Initial resizing
  //   resizeImgSlideshow();

  //   // Event listener for resizing
  //   window.addEventListener("resize", handleWindowResize);

  //   return () => {
  //     // Cleanup event listener
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // }, []);

  // useEffect(() => {
  //   const slideshowElement = document.getElementById("slideshow1");
  //   console.log(slideshowElement, window);
  //   if (slideshowElement) {
  //     // Assuming allinone_bannerRotator is available globally
  //   }
  // }, []);
  const images = [
    // {
    //   original: `${keys.authorizedURL}/assets/images/slideshow1.jpg`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/slideshow2.jpg`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/b2.webp`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/b3.webp`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/b4.webp`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/b5.webp`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/5.webp`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/b6.webp`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/b7.webp`
    // },
    // {
    //   original: `${keys.authorizedURL}/assets/images/b8.webp`
    // }
    {
      original: `${keys.authorizedURL}/assets/images/S1.jpg`
    },
    {
      original: `${keys.authorizedURL}/assets/images/S2.jpg`
    },
    {
      original: `${keys.authorizedURL}/assets/images/S3.jpg`
    },
    {
      original: `${keys.authorizedURL}/assets/images/S4.jpg`
    },
    {
      original: `${keys.authorizedURL}/assets/images/S5.jpg`
    },
  ];

  return (
    <ImageGallery
      items={images}
      showThumbnails={false}
      showNav={false}
      showFullscreenButton={false}
      showPlayButton={false}
      autoPlay={true}
      slideDuration={3000}
      slideInterval={5000}
    />
  );

  // return (
  //   <div className="slideshow">
  //     <div id="slideshow1">
  //       <ImageGallery items={images} showThumbnails={false}  />
  //       <ul className="allinone_bannerRotator_list">
  //         <li data-text-id="#content_ss_1">
  //           <img src="assets/images/slideshow1.jpg" alt="img" />
  //         </li>
  //         <li data-text-id="#content_ss_2">
  //           <img src="assets/images/slideshow2.jpg" alt="img" />
  //         </li>
  //       </ul>
  //       {/* <div id="content_ss_1" className="allinone_bannerRotator_texts">
  //         <div
  //           className="content-slideshow"
  //           data-initial-left="50"
  //           data-initial-top="280"
  //           data-final-left="0"
  //           data-final-top="280"
  //           data-duration="0.7"
  //           data-fade-start="0"
  //           data-delay="0.2"
  //         >
  //           <img
  //             className="content-img"
  //             src="assets/images/slide1-content-1.png"
  //             alt="img"
  //           />
  //         </div>
  //         <div
  //           className="content-slideshow"
  //           data-initial-left="50"
  //           data-initial-top="320"
  //           data-final-left="0"
  //           data-final-top="320"
  //           data-duration="0.6"
  //           data-fade-start="0"
  //           data-delay="0.6"
  //         >
  //           <img
  //             className="content-img"
  //             src="assets/images/slide1-content-2.png"
  //             alt="img"
  //           />
  //         </div>
  //         <div
  //           className="content-link"
  //           data-initial-top="440"
  //           data-final-top="440"
  //           data-duration="0.6"
  //           data-fade-start="0"
  //           data-delay="1"
  //         >
  //           <Link className="link" to={"/productlisting"}>
  //             Shop now
  //           </Link>
  //         </div>
  //       </div>
  //       <div id="content_ss_2" className="allinone_bannerRotator_texts">
  //         <div
  //           className="content-slideshow"
  //           data-initial-top="0"
  //           data-final-top="270"
  //           data-duration="0.6"
  //           data-fade-start="0"
  //           data-delay="0.2"
  //         >
  //           <img
  //             className="content-img"
  //             src="assets/images/slide2-content-1.png"
  //             alt="img"
  //           />
  //         </div>
  //         <div
  //           className="content-slideshow"
  //           data-initial-top="380"
  //           data-final-top="380"
  //           data-final-left="16"
  //           data-duration="0.6"
  //           data-fade-start="0"
  //           data-delay="0.8"
  //         >
  //           <img
  //             className="content-img"
  //             src="assets/images/slide2-content-2.png"
  //             alt="img"
  //           />
  //         </div>
  //       </div>
  //       <div id="content_ss_3" className="allinone_bannerRotator_texts">
  //         <div
  //           className="content-slideshow"
  //           data-initial-top="480"
  //           data-final-top="280"
  //           data-duration="0.6"
  //           data-fade-start="0"
  //           data-delay="0.2"
  //         >
  //           <img
  //             className="content-img"
  //             src="assets/images/slide3-content-1.png"
  //             alt="img"
  //           />
  //         </div>
  //         <div
  //           className="content-slideshow"
  //           data-initial-left="200"
  //           data-initial-top="340"
  //           data-final-left="0"
  //           data-final-top="340"
  //           data-duration="0.6"
  //           data-fade-start="0"
  //           data-delay="0.6"
  //         >
  //           <img
  //             className="content-img"
  //             src="assets/images/slide3-content-2.png"
  //             alt="img"
  //           />
  //         </div>
  //         <div
  //           className="content-link"
  //           data-initial-left="100"
  //           data-initial-top="420"
  //           data-final-left="0"
  //           data-final-top="420"
  //           data-duration="0.6"
  //           data-fade-start="0"
  //           data-delay="1"
  //         >
  //           <a className="link" href="#">
  //             Shop now
  //           </a>
  //         </div>
  //       </div> */}
  //     </div>
  //   </div>
  // );
};

export default Slideshow;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import AppHeaderRoute from "./AppHeaderRoute";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerAction } from "../store/actions";
// import Select from "react-select";
// import { SelectedOption } from "../components/common/commonTypes";
// import { useDispatch } from "react-redux";
// import { useAppSelector } from "../redux/store/hooks";
// import { FINANCIAL_YEAR } from "../redux/constTypes/common";
// import { useAuth } from "../App";
// import { useNavigate } from "react-router-dom";
// import { getFinancialYear } from "../redux/reducers/common";

// type AppHeaderProps = {
//   toggleClick?: () => void;
//   isToggled?: boolean;
// };
const AppHeaderTop = () => {
  const item = useSelector(({ cart }: any) => cart.item);
  const customer = useSelector(({customer}:any)=>customer)
  const wishlist = useSelector((obj: any) => obj.wishlist);

  const dispatch = useDispatch<any>();
  const logout = () => 
    dispatch(customerAction.logout())
  // const navigate = useNavigate();
  // const { user, signout } = useAuth();
  // const { toggleClick = () => {}, isToggled = false } = props;
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const dispatch = useDispatch<any>();
  // const [selectedFinancialYearId, setSelectedFinancialYearId] =
  //   useState<SelectedOption | null>(null);
  // const { financialYear, getFinancialYear } = useAppSelector((obj) => obj);

  // const onClickDropdownOpen = () => {
  //   setDropdownOpen(true);
  // };

  // const logout = () => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   signout(() => {
  //     navigate("/");
  //     window.location.reload();
  //   });
  // };

  // const handleSubmit = () => {
  //   let data = 2022;

  //   dispatch({ type: FINANCIAL_YEAR, payload: data });
  //   // console.log(
  //   //   selectedFinancialYearId,
  //   //   financialYear,
  //   //   getFinancialYear[0].value
  //   // );
  //   // window.location.reload();
  //   setDropdownOpen(false);
  // };

  return (
    <div className="topbar d-none d-xl-block d-lg-block d-md-block">
      <div className="container-fluid" style={{padding:"0px 30px"}} >
        <div className="left-topbar text-uppercase">
          {`WELCOME ${customer?.customername || `GUEST!`}`} 
          {Number(customer?.id) <= 0 && <Link to={"/login"}>{`LOG IN`}</Link> }
          {/* {" OR "}
          <Link to={"/login"}>{`REGISTER`}</Link> */}
        </div>
        <div className="right-topbar" style={{marginRight:"60px"}}>
          <ul className="list-inline">
            <li>
              <div className="btn-group">
                <button
                  className="dropdown dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span>My Settings</span>
                  <i className="pe-7s-angle-down"></i>
                </button>
                <ul className="dropdown-menu">
                  <li>
                  
                    <Link to="/wishlist">
                      <i className="fa fa-heart"></i>
                      {/* {`Wish List (${wishlist?.length || 0})`} */}
                      {`Wish List`}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/cart">
                      <i className="pe-7s-cart"></i>
                      {item?.length > 0 && <span>{item.length}</span>}
                    </Link>
                  </li> */}
                  <li>
                    <a href="check-out.html">
                      <i className="fa fa-share"></i> Checkout
                    </a>
                  </li>
                  {Number(customer?.id)>0 && 
                    <li>
                    <button className="btn btn-light btn-sm btn-block" onClick={()=>logout()}>
                      <i className="fa fa-sign-out"></i> Logout
                    </button>
                  </li>
                  }
                </ul>
              </div>
            </li>
            {/* <li>
                            <form action="#">
                                <div className="btn-group">
                                    <button className="dropdown dropdown-toggle" data-toggle="dropdown">
                                    <span>Language:</span>
                                    en
                                    <i className="pe-7s-angle-down"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a href="#"><img src="assets/images/flags/United-Kingdom.png" alt="English" title="English"/> English</a>
                                        </li>
                                        <li>
                                            <a href="#"><img src="assets/images/flags/France.png" alt="France" title="France"/> France</a>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </li>
                        <li>
                            <form action="#">
                                <div className="btn-group">
                                    <button className="dropdown dropdown-toggle" data-toggle="dropdown">
                                    <span>Currency:</span>
                                    USD
                                    <i className="pe-7s-angle-down"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a href="#">€ Euro</a>
                                        </li>
                                        <li>
                                            <a href="#">£ Pound Sterling</a>
                                        </li>
                                        <li>
                                            <a href="#">$ US Dollar</a>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppHeaderTop;

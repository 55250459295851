const PrivacyPolicy = () => {
  return (
    <div className="our-team-content">
      <div className="header-our-team">
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>
            Welcome to The Woven Wonders ("we," "our," or "us"). We value your
            privacy and are committed to protecting your personal information.
            This Privacy Policy outlines how we collect, use, disclose, and
            safeguard your personal information when you visit our website and
            make use of our services. By accessing or using our website, you
            consent to the practices described in this Privacy Policy.
          </p>
        </div>
      </div>

      <hr className="gray-line" />
      <div className="the-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div className="team-item">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Information We Collect</label>
                    </h4>
                    We may collect various types of personal information from
                    you, including but not limited to: Personal Identifiable
                    Information: This may include your name, email address,
                    postal address, phone number, payment information, and other
                    contact details. Browsing Information: We may collect data
                    related to your browsing activity on our website, such as
                    your IP address, browser type, device information, and
                    cookies. Account Information: If you create an account on
                    our website, we may collect additional information like your
                    username, password, and preferences. Order Information: When
                    you make a purchase, we collect information related to your
                    order, such as the products you bought, the transaction
                    amount, and shipping details. Communication Information: Any
                    communications you have with us, such as customer service
                    inquiries or feedback, may be stored.
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>How We Use Your Information</label>
                    </h4>
                    We use the information we collect for various purposes,
                    including: Providing and Improving Services: To process your
                    orders, manage your account, and improve your browsing
                    experience on our website. Personalization: To personalize
                    your shopping experience and show you product
                    recommendations based on your preferences. Communication: To
                    respond to your inquiries, provide customer support, and
                    send you updates regarding your orders. Marketing: With your
                    consent, we may send you promotional materials, special
                    offers, and newsletters. You can opt-out of marketing
                    communications at any time. Analytics: To analyze website
                    usage and user behavior, which helps us enhance our website
                    and services.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Information Sharing</label>
                    </h4>
                    We may share your personal information with third parties in
                    the following circumstances: Service Providers: We may share
                    your information with trusted third-party service providers
                    who help us operate our business, process payments, fulfill
                    orders, or conduct website analytics. Legal Obligations: We
                    may disclose your information when required by law, or when
                    we believe in good faith that such action is necessary to
                    comply with a legal obligation or protect our rights,
                    privacy, safety, or property. Business Transfers: If we are
                    involved in a merger, acquisition, or sale of all or a
                    portion of our assets, your information may be transferred
                    as part of that transaction, subject to the same privacy
                    protection.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Cookies and Tracking Technologies</label>
                    </h4>
                    We use cookies and other tracking technologies to collect
                    and store certain information about your visit to our
                    website. These technologies help us understand your
                    preferences, enhance your user experience, and provide
                    targeted advertising. You can adjust your browser settings
                    to manage cookies preferences or opt-out of certain tracking
                    technologies.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Data Security</label>
                    </h4>
                    We implement appropriate security measures to protect your
                    personal information from unauthorized access, disclosure,
                    alteration, or destruction. However, please note that no
                    data transmission over the internet or storage system is
                    100% secure.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Your Rights</label>
                    </h4>
                    You have the right to access, correct, update, or delete
                    your personal information held by us. If you wish to
                    exercise any of these rights or have any concerns about your
                    privacy, please contact us using the information provided in
                    the "Contact Us" section below.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Children's Privacy</label>
                    </h4>
                    Our services are not directed to individuals under the age
                    of 13. We do not knowingly collect personal information from
                    children under 13 years of age. If you are a parent or
                    guardian and believe your child has provided us with
                    personal information without your consent, please contact
                    us, and we will take appropriate action to remove the
                    information.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Changes to the Privacy Policy</label>
                    </h4>
                    We may update this Privacy Policy from time to time. The
                    revised policy will be effective from the date posted on our
                    website. We encourage you to review this policy periodically
                    to stay informed about how we are protecting your
                    information.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>Contact Us</label>
                    </h4>
                    If you have any questions, concerns, or requests regarding
                    this Privacy Policy or our data practices, please contact us
                    at: 
                    <br></br>The Woven Wonders 
                    <br></br>7 Nilkanth Duplex Gotri Vadodara 
                    <br></br>Phone No. – 6355373488 
                    <br></br>E-mail ID – support@thewovenwonders.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

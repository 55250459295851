/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import AppHeaderRoute from "./AppHeaderRoute";
import { Link } from "react-router-dom";

const AppFooter = () => {
  return (
    <footer style={{ borderTop: "2px solid #CB8161" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <h3>LOCATION</h3>
            <div className="address">
              A 19, New Mount Villa, After Nilamber Circle,
              <br /> opp. Akshar pavilion, Vasna Bhayli Road - 390012
            </div>
            <p>
              Tel. <a href="tel:0916355373488">+91 6355373488</a>
            </p>
            <p>
              Email.{" "}
              <a href="mailto:support@thewovenwonders.com">
                support@thewovenwonders.com
              </a>
            </p>
            <ul className="list-social">
              <li>
                <a
                  href="https://www.facebook.com/thewovenwonderindia?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                  className="facebook"
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/thewovenwondersindia/"
                  target="_blank"
                  className="linkedin"
                  rel="noreferrer"
                >
                  <i className="fa fa-linkedin" />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/thewovenwondersindia?igshid=MzRlODBiNWFlZA"
                  className="instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram" />
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6">
            <h3>POLICIES</h3>
            <ul className="list-link ps-0">
              <li>
                <Link
                  to={{ pathname: `/page/terms-and-conditions` }}
                >{`TERMS & CONDITION`}</Link>
              </li>
              <li>
                <Link
                  to={{ pathname: `/page/shipping-delivery-policy` }}
                >{`SHIPPING`}</Link>
              </li>
              <li>
                <Link
                  to={{ pathname: `/page/refund-policy` }}
                >{`RETURNS & REFUND`}</Link>
              </li>
              <li>
                <Link
                  to={{ pathname: `/page/privacy-policy` }}
                >{`PRIVACY POLICY`}</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-6">
            <h3>COMPANY</h3>
            <ul className="list-link ps-0">
              <li>
                <Link
                  to={{ pathname: `/page/contact-us` }}
                >{`CONTACT US`}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="copyright" style={{ textAlign: "center" }}>
            ©2023 thewovenwonders | All Rights Reserved
          </div>

          {/* <ul className="list-payment">
              <li>
                <a href="#">
                  <img src="assets/images/visa.png" alt="img" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/images/paypal.png" alt="img" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/images/2co.png" alt="img" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/images/ae.png" alt="img" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/images/skrill.png" alt="img" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/images/gw.png" alt="img" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/images/wu.png" alt="img" />
                </a>
              </li>
            </ul> */}
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;

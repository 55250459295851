/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { get_category_list } from "../store/actions/productCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { keys } from "../config/keys";

const AppHeaderMenu = () => {
  const dispatch = useDispatch<any>();
  const customer = useSelector(({ customer }: any) => customer)
  const category_list = useSelector((obj: any) => obj.category_list);

  const _get_category_list = () => dispatch(get_category_list());

  const [count, setcount] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    _get_category_list();
    const minimalMenu = document.querySelector(".minimal-menu");


    const addedMenu = document.querySelectorAll(".minimal-menu-button");


    if (addedMenu.length > 1) return;
    if (!minimalMenu) return;

    // addedMenu[0].addEventListener("click", () => {
    //   alert('');
    // })

    console.log('addedmenu', addedMenu)

    // Add minimal-menu-button before .minimal-menu
    minimalMenu.insertAdjacentHTML(
      "beforebegin",
      `<label class="minimal-menu-button" for="mobile-nav">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        </label><input class="minimal-menu-button" type="checkbox" id="mobile-nav" name="mobile-nav" />`
    );

    // // Add classes to menu elements
    minimalMenu
      .querySelectorAll("ul.sub-menu")
      .forEach((ul: any) => ul.parentElement.classList.add("submenu"));
    minimalMenu
      .querySelectorAll("div.menu-wrapper")
      .forEach((div: any) =>
        div.parentElement.classList.add("megamenu", "submenu")
      );

    // Add show-submenu input before ul.sub-menu and div.menu-wrapper
    minimalMenu
      .querySelectorAll("ul.sub-menu")
      .forEach((ul) =>
        ul.insertAdjacentHTML(
          "beforebegin",
          '<input class="show-submenu" type="checkbox" />'
        )
      );
    minimalMenu
      .querySelectorAll("div.menu-wrapper")
      .forEach((div) =>
        div.insertAdjacentHTML(
          "beforebegin",
          '<input class="show-submenu" type="checkbox" />'
        )
      );

  }, []);

  // useEffect(() => {
  //   console.log(category_list.filter((x: any) => x.parentcategoryid === 0));
  // }, [category_list]);

  const onClickMenu = () => {
    alert();
  }

  const onClickLink = () => {
    const menuchecked = document.querySelector("#mobile-nav");
    const button = document.querySelector(".minimal-menu-button");
    //@ts-ignore
    button.click()

  }

  return (
    <nav className="main-nav">
      {/* <label className="minimal-menu-button" onClick={onClickMenu}>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </label> */}
      <div className="minimal-menu" >
        <div className="d-block d-sm-block d-md-none">
          <div className="menu px-4 py-3 text-uppercase" >
            <h5>{`WELCOME ${customer?.customername || `GUEST!`}`}  </h5>
          </div>
          <div className="bborder-top border border-dark-subtle"></div>
          <ul className="menu">
            <li className="menu-item">
              <Link to="/wishlist" onClick={onClickLink}>
                <i className="fa fa-heart text-danger" ></i>

                {`  WISH LIST`}
              </Link>

            </li>
          </ul>
          <div className="bborder-top border border-dark-subtle"></div>
        </div>
        <ul className="menu">
          <li className="current-menu-item">
            <Link to="/" onClick={onClickLink}>HOME</Link>
            {/* <a href="/">Home</a> */}
          </li>
          <li className="">
            <Link to="/about-us"   onClick={onClickLink}>ABOUT US</Link>
            {/* <a href="/about-us">About us</a> */}
          </li>
          {/* <li>
            <a href="shop-fullwidth.html">SHOP</a>
            <div className="menu-wrapper">
              {category_list.length > 0 &&
                category_list
                  .filter((item: any, index: number) => index > 1 && index < 6)
                  .map((obj: any, key: number) => {
                    if (
                      obj.isactive === 1 &&
                      obj.isdelete === 0 &&
                      obj.displayonweb === 1
                    )
                      return (
                        // <div className="col-md-3" style={{ padding: "10px" }}>
                        //   <div
                        //     className="fashion-store"
                        //     style={{
                        //       backgroundImage: `url('${keys.ServicePath}/${obj.categoryimage}')`
                        //     }}
                        //   >
                        //     <a href="/productlisting">
                        //       <h3>{obj.category}</h3>
                        //       <div className="overlay"></div>
                        //     </a>
                        //   </div>
                        // </div>
                        <div
                          className="col-lg-3 col-md-4 col-sm-6"
                          key={Math.random()}
                        >
                          <h3>{obj.category}</h3>
                          <hr />
                          <p>
                            {`${obj?.description}
                        ${obj?.tagline}`}
                          </p>
                          <div className="media">
                            <div className="media-left">
                              <a href="shop-with-sidebar.html">
                                <img
                                  className="img-responsive"
                                  src={`${keys.ServicePath}/${obj.categoryimage}`}
                                  alt="img"
                                />
                              </a>
                            </div>
                            <div className="media-body">
                              <ul>
                                <li>
                                  <a href="shop-with-sidebar.html">
                                    {obj?.parentcategory}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                  })}
            </div>
          </li> */}
          <li>
            {/* <a href="#">SAREES</a> */}
            <Link to={{ pathname: `/productlisting` }}>{`SAREES`}</Link>
            <ul className="sub-menu">
              {category_list.length > 0 &&
                category_list.filter((x: any) => x.parentcategoryid === 22).map((obj: any) => {
                  if (obj.isactive === 1 && obj.isdelete === 0)
                    return (
                      <li style={{ display: "flex" }} key={Math.random()}>
                        <Link
                          to={{ pathname: `/productlisting/${obj.id}` }}
                          state={{ categoryId: obj?.id }}
                          onClick={onClickLink}
                        >
                          {obj.category.toUpperCase()}
                        </Link>
                        {/* <a href="/productlisting">{obj.category}</a> */}
                      </li>
                    );
                })}
            </ul>
          </li>
          {category_list
            .filter((x: any) => x.parentcategoryid === 0)
            .map((y: any) => (
              <li>
                <Link
                  to={{ pathname: `/productlisting/${y.id}` }}
                  state={{ categoryId: y?.id }}
                >{`${y.label}`}</Link>
                <ul className="sub-menu">
                  {category_list.length > 0 &&
                    category_list
                      .filter((x: any) => x.parentcategoryid === y.id)
                      .map((obj: any) => {
                        if (obj.isactive === 1 && obj.isdelete === 0)
                          return (
                            <li style={{ display: "flex" }} key={Math.random()}>
                              <Link
                                to={{ pathname: `/productlisting/${obj.id}` }}
                                state={{ categoryId: obj?.id }}
                                onClick={onClickLink}
                              >
                                {obj.category.toUpperCase()}
                              </Link>
                              {/* <a href="/productlisting">{obj.category}</a> */}
                            </li>
                          );
                      })}
                </ul>
              </li>
            ))}
          {/* <li className="">
            <Link to="#">ACCESSIORIES</Link>
          </li> */}
          <li className="">

            <Link to={{ pathname: `/blogs` }} onClick={onClickLink}>{`BLOG`}</Link>
          </li>
          <li>
            <Link to={{ pathname: `/page/contact-us` }} onClick={onClickLink}>{`CONTACT`}</Link>
          </li>
          <li className="hidden-xs">
            <div className="wrap-search">
              <form className="search-form" onSubmit={(e) => { e.preventDefault(); navigate("/search", { state: search }); }}>
                <input type="text" placeholder="Search.." onChange={(e) => setSearch(e.target.value)} />
                <button type="submit">
                  <i className="pe-7s-search"></i>
                </button>
              </form>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default AppHeaderMenu;

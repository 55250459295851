import { keys } from "../../config/keys";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../common/axios";
import { _dispatchPayload } from "./dispatchPayload";
import { PRODUCT_LIST, PRODUCT_DETAIL_BY_ID } from "../constTypes";

export const getProductList = createAsyncThunk("getProductList", async () => {
  const ProductList = await fetch(`${keys.ServicePath}/product/products`).then(
    (res) => res.json()
  );
//  console.log(ProductList);
  return ProductList;
});

export const postProductList = createAsyncThunk(
  "postProductList",
  async (productData) => {
    const postProductListResponse = await fetch(
      `${keys.ServicePath}/product/products/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(productData)
      }
    ).then((res) => res.json());

    return postProductListResponse;
  }
);

export const get_product_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/product`)
    .then((res) => {
      let data = res.data.rows[0].map((item) => {
        let t = res.data.rows[1].filter((x) => x.productid === item.id);
        let at = res.data.rows[2].filter((x) => x.productid === item.id);
        let fbt = res.data.rows[3]
          .filter((x) => x.productid === item.id)
          .map((y) => y.refproductid);

        let freq_prd = res.data.rows[0].filter((x) => fbt.includes(x.id));

        item.product_images = t;
        item.attribute_values = at;
        item.frequent_products = freq_prd;
        return item;
      });
      dispatch(_dispatchPayload(PRODUCT_LIST, data));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

// export const store_product_dd = (callback) => async (dispatch) => {
//   await axios
//     .get(`${keys.ServicePath}/api/product/store_product_dd`)
//     .then((res) => {
//       dispatch(_dispatchPayload(PRODUCT_LIST, res.data.rows));
//       if (callback?.onSuccess) callback.onSuccess(res.data);
//     })
//     .catch((e) => {
//       if (callback?.onError) callback?.onError(e);
//     });
// };

export const save_product = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/product`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_product = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/product/delete`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const getProductDetailById = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/product/get_product_detail_by_id/${id}`)
    .then((res) => {
      let data = {
        ...res.data.rows[0][0],
        product_images: [...res.data.rows[1]],
        attribute_values: [...res.data.rows[2]]
      };
  
      dispatch(_dispatchPayload(PRODUCT_DETAIL_BY_ID, { ...data }));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from "react-redux";
import AppHeaderMenu from "./AppHeaderMenu";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const AppHeaderRoute = () => {
  const item = useSelector(({ cart }: any) => cart.item);

  return (
    <header className="gray-bg">
      <div className="">
        <a className="logo" href="/">
          <img
            src={require("../assets/images/twwLogo.png")}
            alt="img"
            height={70}
          />
        </a>

        <div className="header-social">
          <ul className="list-social">
            <li>
              <a
                href="https://www.facebook.com/thewovenwonderindia?mibextid=LQQJ4d"
                className="facebook"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/thewovenwondersindia/"
                className="linkedin"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/thewovenwondersindia?igshid=MzRlODBiNWFlZA"
                className="instagram"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            {/* <li>
                <a href="#" className="vk">
                  <i className="fa fa-vk"></i>
                </a>
              </li>
              <li>
                <a href="#" className="behance">
                  <i className="fa fa-behance"></i>
                </a>
              </li> */}
          </ul>
        </div>

        <div className="top-cart d-flex">
          <Link to="/login" className="pe-2">
            <i className="pe-7s-user"></i>
          </Link>
          <Link to="/cart" className="ps-2">
            <i className="pe-7s-cart"></i>
            {item?.length > 0 && <span>{item?.length}</span>}
          </Link>
        </div>

        <AppHeaderMenu />
      </div>
    </header>
  );
};

export default AppHeaderRoute;

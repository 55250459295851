import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../common/axios";
import { keys } from "../../config/keys";
import { _dispatchPayload } from "./dispatchPayload";
import { CATEGORY_BY_ID, CATEGORY_LIST } from "../constTypes";

export const get_category_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/category`)
    .then((res) => {
      dispatch(_dispatchPayload(CATEGORY_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_category_by_id = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/category/${id}`)
    .then((res) => {
      dispatch(_dispatchPayload(CATEGORY_BY_ID, res.data.rows[0]));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const save_category = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/category`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_category = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/category/delete`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

//import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import GoogleMapReact from "google-map-react";
const Contact = () => {
  // const libraries: any = ["places"];
  // const mapContainerStyle = {
  //   width: "100vw",
  //   height: "100vh"
  // };
  // const center = {
  //   lat: 7.2905715, // default latitude
  //   lng: 80.6337262 // default longitude
  // };

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyDJD2RrJbzpcGSs0hYCi5VoxjKNYf8ZpOU",
  //   libraries
  // });

  const location = {
    address: "A 19,New Mount Villa",
    lat: 22.298336672412727,
    lng: 73.13139004570344
  };

  return (
    <div className="our-team-content">
      <div className="header-our-team">
        <div className="container">
          <h1>GET IN TOUCH</h1>
          <p>
            If you have any queries, concerns or suggestions, please contact us.
          </p>
        </div>
      </div>

      <hr className="gray-line" />
      <div className="the-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12">
                  <div className="team-item">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <h4 style={{ fontSize: "18px" }}>
                          <div
                            className="alert alert-info margin-bottom-unset padding-unset d-flex align-items-center"
                            style={{ fontSize: "18px" }}
                          >
                            <i
                              className="fa fa-map-marker pe-3"
                              style={{ fontSize: "28px" }}
                            />
                            A 19, New Mount Villa, After Nilamber Circle, opp.
                            Akshar pavilion, Vasna Bhayli Road -390012
                          </div>
                        </h4>
                      </div>
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <h4 style={{ fontSize: "18px" }}>
                          <div
                            className="alert alert-info margin-bottom-unset padding-unset"
                            style={{ fontSize: "18px" }}
                          >
                            <i
                              className="fa fa-phone pe-3"
                              style={{ fontSize: "28px" }}
                            />
                            +91 6355373488
                          </div>
                        </h4>
                      </div>

                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <h4 style={{ fontSize: "18px" }}>
                          <div
                            className="alert alert-info margin-bottom-unset padding-unset"
                            style={{ fontSize: "18px" }}
                          >
                            <i
                              className="fa fa-envelope pe-3"
                              style={{ fontSize: "28px" }}
                            />
                            support@thewovenwonders.com
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {/* <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={center}
              >
                <Marker position={center} />
              </GoogleMap> */}
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDJD2RrJbzpcGSs0hYCi5VoxjKNYf8ZpOU"
                }}
                defaultCenter={location}
                defaultZoom={15}
              >
                <LocationPin
                  lat={location.lat}
                  lng={location.lng}
                  text={location.address}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LocationPin = ({ text }: any) =>
  <div className="pin">
    {/* <Icon icon={locationIcon} className="pin-icon" /> */}
    <i className="fa fa-map-marker" style={{fontSize:"50px", color:"red"}}/>
    <p className="pin-text">
      {text}
    </p>
  </div>;
export default Contact;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

const AboutUs = () => {
  const render_carousel = () => {
    const backgroundElements = document.querySelectorAll(".background_bg");
    backgroundElements.forEach((element: any) => {
      const attr = element.getAttribute("data-img-src");
      if (attr) {
        element.style.backgroundImage = `url(${attr})`;
        element.style.height = `350px`;
      }
    });
  };

  useEffect(() => {
    render_carousel();
  }, []);

  return (
    <div className="m-4 staggered-animation-wrap our-team-content">
      <div className="header-our-team">
        <div className="container">
          <h2>WHO WE ARE</h2>
        </div>
      </div>
      <div className="section">
        <div className="custom-container">
          <div className="row align-items-top">
            <div className="col-lg-6">
              <div className="about_img scene mb-4 mb-lg-0">
                <img src="assets/images/S3.jpg" alt="about_img" height={500} />
              </div>
            </div>
            <div className="col-lg-6" style={{fontSize:"15px"}}>
              <p>
                The Woven Wonders (TWW) is making an effort to re-introduce the
                Handloom, Weaves & Handmade Products of “INDIA” by creatively
                blending it back into our wardrobes/homes & our day-to-day life.
              </p>
              <p>
                Touching the rare & old, not so known, yet beautiful clothing
                and texture in the Indian rural heartland and aligning the same
                with our modern-day attire. We aim to take the people of India a
                step closer to recognising & appreciating this beautiful
                heritage of ours.{" "}
              </p>
              <p>
                When you purchase from us, you are taking a step towards
                reviving the heritage of our Country and also contributing
                towards the Social Empowerment of Artisans & Craftsman by buying
                their produce.{" "}
              </p>
              <p>
                We take great pride in supporting local artisans and craftsmen
                who have honed their skills over generations. By partnering with
                them, we are able to showcase their unique creations and provide
                our customers with authentic, one-of-a-kind piece.{" "}
              </p>
              <p>
                Our commitment to quality and customer satisfaction is at the
                core of everything we do. We strive to provide our customers
                with a seamless shopping experience, from browsing our products
                online to receiving their purchases at their doorstep. We offer
                excellent customer service, ensuring that any questions or
                concerns are addressed promptly and professionally.{" "}
              </p>
              <p>
                Thank you for choosing us as your trusted source for authentic,
                high-quality Indian Handloom, Weaves & Handmade Products of
                “INDIA”…..
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="custom-container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="heading_s1 text-center">
                <h2>The Team who holds TWW Close to their Heart</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="about_img scene mb-4 mb-lg-0 d-flex justify-content-center">
                <img src="assets/images/S1.jpg" alt="about_img" height={500} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_img scene mb-4 mb-lg-0 d-flex justify-content-center">
                <img src="assets/images/S2.jpg" alt="about_img" height={500} />
              </div>
            </div>
            {/* <div className="col-lg-9">
              <div
                className="testimonial_wrap testimonial_style1 carousel_slider owl-carousel owl-theme nav_style2"
                data-nav="false"
                data-dots="true"
                data-center="true"
                data-loop="true"
                data-autoplay="true"
                data-items="1"
              >
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                    <p>
                      An HR Professional with 20 years of rich Corporate
                      Experience, Vandana hung her boots while at the helm of
                      her career to pursue this start up which is something she
                      wanted to do always.
                    </p>
                    <p>
                      In her growing years, she has travelled to various parts
                      of the Country. She would always get fascinated by the
                      local produce and the wonderful hand made items. As and
                      when possible, she would collect these handmade and
                      handwoven products and add them to her collection.
                    </p>
                    <p>
                      Over the years and specifically post the Corona pandemic,
                      she observed that the age old art has been dying and the
                      artisans are deprived of business.
                    </p>
                    <p>
                      The Woven Wonders (TWW) is her initiative to revive back
                      our rich cultural heritage and bring it back to the
                      forefront.
                    </p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/images/user_img1.jpg" alt="user_img1" />
                    </div>
                    <div className="author_name">
                      <h6>Vandana Nambiar</h6>
                      <span>HR Manager</span>
                    </div>
                  </div>
                </div>
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                    <p>
                      A Doctor with a heart that goes out towards the creative
                      blend of life. A Bharatnatyam Dancer, a Yoga Instructor,
                      an Art Enthusiast and a person who is so deeply rooted to
                      the culture of this Country.
                    </p>
                    <p>
                      Sheetal is a very creative mind who always wanted to reach
                      out to the Community at large through some medium whereby
                      she could connect to the grass root level. She has joined
                      hands with TWW to explore the rich heritage of this
                      country through its artisans and art work.
                    </p>
                    <p>
                      Sheetal comes from a humble background whereby she has
                      seen her parents working towards a lot of social causes
                      and contributing to the society at large in their own way.
                    </p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/images/user_img2.jpg" alt="user_img2" />
                    </div>
                    <div className="author_name">
                      <h6>Dr. Sheetal Dani</h6>
                      <span>Designer</span>
                    </div>
                  </div>
                </div>
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                    <p>
                      A Textile Designer by education and a Fashion Designer by
                      profession. Shivani has been in the fashion Industry and
                      has her own ethnic boutique for the last 35 years. She has
                      tremendous knowledge of textile and designing. Shivani
                      always recommends her clients to wear natural and handmade
                      cloth as she understands the ethnicity behind it.
                    </p>
                    <p>
                      Shivani has joined hands with TWW to guide the team and
                      ensure that we bring the best weave and the best handmade
                      cloth to our clients.
                    </p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/images/user_img3.jpg" alt="user_img3" />
                    </div>
                    <div className="author_name">
                      <h6>Shivani Kikani</h6>
                      <span>Boutique Designer</span>
                    </div>
                  </div>
                </div>
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                    <p>
                      A fashionist by heart who has an eye for styling. Udaya
                      has an amazing taste on styling an individual. Not only
                      she can visualize a complete look but she also has a sense
                      of adventure which makes the ensemble stand out. She has
                      been associated with NGOs in the past who have worked
                      towards awareness of art, design and culture.
                    </p>
                    <p>
                      Udaya has joined hands with TWW to guide the team in
                      creating the entire ensemble for the client which would be
                      stylist, destructive and unique at the same time.
                    </p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/images/user_img4.jpg" alt="user_img4" />
                    </div>
                    <div className="author_name">
                      <h6>Udaya Menon</h6>
                      <span>Fashionist</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

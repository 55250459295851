/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartAction, customerAction, productAction } from "../../store/actions";
import { Link } from "react-router-dom";
import { keys } from "../../config/keys";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BestSellerProductList = () => {
  const dispatch = useDispatch<any>();
  const _get_product_list = () => dispatch(productAction.get_product_list());
  const product_list = useSelector((obj: any) => obj.product_list);
  const customer = useSelector((obj: any) => obj.customer);
  const [productList, setProductList] = useState<any>([]);

  useEffect(() => {
    _get_product_list();
    //_get_product_detail();
  }, []);

  useEffect(() => {
    setProductList([...product_list]);
  }, [product_list]);

  const addCart = (item: any) => {
    const { attribute_values, ...others } = item;
    let p = {
      ...others,
      productid: others.id,
      quantity: 1
    };
    dispatch(cartAction.addCartItem(p));
  };

  const get_item_status = (item: any) => {
    let label = "HOT";
    let itemClass = "hot";
    if (Number(item.stock) <= 0) {
      label = "OUT OF STOCK";
      itemClass = "outofstock";
    }

    return <div className={itemClass}>{label}</div>;
  };

  return (
    <div className="container block-content">
      <h3>BEST SELLERS</h3>
      <div id="1-carousel-1">
        <div className="box-content">
          <div className="showcase">
            <div className="row">
              <div className="box-product">
                {productList
                  .filter((y: any, idx: number) => idx < 12)
                  .map((obj: any) => {
                    let imgList = obj.product_images;

                    let thumbImage =
                      imgList &&
                      imgList.filter(
                        (thumbImageObj: any) => thumbImageObj.isthumbnail === 1
                      );

                    // let mainImage =
                    //   thumbImage.length > 0 &&
                    //   imgList.filter(
                    //     (mainImageObj: any) =>
                    //       mainImageObj.id !== thumbImage[0].id
                    //   );

                    let mainImage = imgList.filter(
                      (x: any) => x.isdefault === 1
                    );
                    if (mainImage.length <= 0 && imgList.length > 0) {
                      mainImage = [imgList[0]];
                    }
                    if (imgList.length >= 2) {
                      thumbImage = [imgList[1]];
                    }
                    return (
                      <div
                        className="col-lg-3 col-md-4 col-sm-6 col-sx-12"
                        key={Math.random()}
                      >
                        <div className="product-item">
                          <div className="product-thumb">
                            <div className="product-lable">
                              {get_item_status(obj)}
                            </div>
                            <div className="main-img">
                              <Link to={`/productdetail/${obj.id}`}>
                                {mainImage && mainImage.length > 0 ? (
                                  <LazyLoadImage
                                    src={`${keys.ServicePath}/${mainImage[0].filepath}`}
                                    width={250}
                                    height={350}
                                    style={{
                                      width: "250px",
                                      margin: "auto",
                                      minHeight: "350px",
                                      height: "350px"
                                    }}
                                    effect="blur"
                                    className="img-responsive"
                                    alt="TWW"
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={require("../../assets/images/noImage.jpg")}
                                    width={250}
                                    height={350}
                                    style={{
                                      width: "250px",
                                      margin: "auto",
                                      minHeight: "350px",
                                      height: "350px"
                                    }}
                                    effect="blur"
                                    className="img-responsive"
                                    alt="TWW"
                                  />
                                )}
                              </Link>
                            </div>
                            <div className="overlay-img">
                              {thumbImage && thumbImage.length > 0 ? (
                                <LazyLoadImage
                                  src={`${keys.ServicePath}/${thumbImage[0].filepath}`}
                                  width={250}
                                  height={350}
                                  style={{
                                    width: "250px",
                                    margin: "auto",
                                    minHeight: "350px",
                                    height: "350px"
                                  }}
                                  effect="blur"
                                  className="img-responsive"
                                  alt="TWW"
                                />
                              ) : (
                                <LazyLoadImage
                                  src={`${keys.ServicePath}/${mainImage[0].filepath}`}
                                  width={250}
                                  height={350}
                                  style={{
                                    width: "250px",
                                    margin: "auto",
                                    minHeight: "350px",
                                    height: "350px"
                                  }}
                                  effect="blur"
                                  className="img-responsive"
                                  alt="TWW"
                                />
                              )}
                            </div>
                            <div className="d-flex product-button">
                              <Link
                                to={`/productdetail/${obj.id}`}
                                className="details"
                              >
                                <i className="pe-7s-search" />
                              </Link>

                              {Number(obj.stock) > 0 && (
                                <button
                                  type="button"
                                  className="details"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Add to Cart"
                                  onClick={() => addCart(obj)}
                                >
                                  <i className="pe-7s-cart" />
                                  {/* <span>Add to Cart</span> */}
                                </button>
                              )}
                              {/* <button
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                className="me-2 details"
                                title="Compare this Product"
                              >
                                <i className="pe-7s-repeat" />
                              </button> */}
                              <button
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add to Wishlist"
                                className="details"
                                onClick={() =>
                                  dispatch(
                                    customerAction.add_wishlist({
                                      customerid: customer?.id,
                                      productid: obj.id
                                    })
                                  )
                                }
                              >
                                <i className="pe-7s-like" />
                              </button>
                            </div>
                          </div>
                          <h4 className="product-name">
                            <Link
                              to={`/productdetail/${obj.id}`}
                              className="details"
                            >
                              {obj.productname}
                            </Link>
                          </h4>
                          <p className="product-price">
                            {keys.currencyFormat(
                              obj.online_sales_price_with_tax
                            )}
                          </p>
                          {/* <div className="group-buttons">
                            {Number(obj.stock) > 0 ? (
                              <button
                                type="button"
                                className="add-to-cart "
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add to Cart"
                                onClick={() => addCart(obj)}
                              >
                                <span>Add to Cart</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="add-to-cart"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add to Cart"
                                disabled
                              >
                                <span>OUT OF STOCK</span>
                              </button>
                            )}
                            <button
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              className="me-2"
                              title="Compare this Product"
                            >
                              <i className="pe-7s-repeat"></i>
                            </button>
                            <button
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add to Wishlist"
                            >
                              <i className="pe-7s-like"></i>
                            </button>
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="section section-padding background-img bg-img-2 p-t-60 p-b-70 m-b-70"> */}
      {/* <section className="section">
        <div className="section-container">
          <div className="block block-products layout-2 slider">
            <div className="block-widget-wrap">
              <div className="block-title">
                <h2>Trending Products</h2>
              </div>
              <div className="block-content">
                <div className="content-product-list slick-wrap">
                  <div
                    className="slick-sliders products-list grid"
                    data-slidestoscroll="true"
                    data-dots="false"
                    data-nav="1"
                    data-columns4="1"
                    data-columns3="2"
                    data-columns2="2"
                    data-columns1="3"
                    data-columns1440="4"
                    data-columns="4"
                  >
                    {productList
                      .filter((y: any, idx: number) => idx < 12)
                      .map((obj: any) => {
                        let imgList = obj.product_images;

                        let thumbImage =
                          imgList &&
                          imgList.filter(
                            (thumbImageObj: any) =>
                              thumbImageObj.isthumbnail === 1
                          );

                        // let mainImage =
                        //   thumbImage.length > 0 &&
                        //   imgList.filter(
                        //     (mainImageObj: any) =>
                        //       mainImageObj.id !== thumbImage[0].id
                        //   );

                        let mainImage = imgList.filter(
                          (x: any) => x.isdefault === 1
                        );
                        if (mainImage.length <= 0 && imgList.length > 0) {
                          mainImage = [imgList[0]];
                        }
                        if (imgList.length >= 2) {
                          thumbImage = [imgList[1]];
                        }
                        return (
                          <div className="item-product slick-slide">
                            <div className="items">
                              <div className="products-entry clearfix product-wapper">
                                <div className="products-thumb">
                                  <div className="product-lable">
                                    <div className="hot">Hot</div>
                                  </div>
                                  <div className="product-thumb-hover">
                                    <Link
                                      to={`/productdetail/${obj.id}`}
                                      className="details"
                                    >
                                      {mainImage && mainImage.length > 0 ? (
                                        <>
                                          <img
                                            className="img-responsive post-image"
                                            style={{
                                              width: "250px",
                                              margin: "auto",
                                              height: "350px"
                                            }}
                                            src={`${keys.ServicePath}/${mainImage[0].filepath}`}
                                            alt="img"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="img-responsive post-image main-img"
                                            style={{
                                              width: "250px",
                                              margin: "auto",
                                              height: "350px"
                                            }}
                                            src={require("../../assets/images/noImage.jpg")}
                                            alt="img"
                                          />
                                        </>
                                      )}
                                      {thumbImage && thumbImage.length > 0 ? (
                                        <>
                                          <img
                                            className="img-responsive hover-image back overlay-img"
                                            style={{
                                              width: "250px",
                                              margin: "auto",
                                              height: "350px"
                                            }}
                                            src={`${keys.ServicePath}/${thumbImage[0].filepath}`}
                                            alt="img"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="img-responsive hover-image back"
                                            style={{
                                              width: "250px",
                                              margin: "auto",
                                              height: "350px"
                                            }}
                                            src={require("../../assets/images/noImage.jpg")}
                                            alt="img"
                                          />
                                        </>
                                      )}
                                    </Link>
                                  </div>
                                  <div className="product-button">
                                    <div
                                      className="btn-add-to-cart"
                                      data-title="Add to cart"
                                    >
                                    
                                      <button
                                        type="button"
                                        className="product-btn button"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Add to Cart"
                                        onClick={() => addCart(obj)}
                                      >
                                        <i className="fa fa-cart" />
                                        <span>Add to Cart</span>
                                      </button>
                                    </div>
                                    <div
                                      className="btn-wishlist"
                                      data-title="Wishlist"
                                    >
                                      <button
                                        className="product-btn"
                                        type="button"
                                      >
                                        Add to wishlist
                                      </button>
                                    </div>
                                    <span
                                      className="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <Link
                                        to={`/productdetail/${obj.id}`}
                                        className="quickview quickview-button"
                                      >
                                        <i className="pe-7s-search"></i>
                                      </Link>
                                      
                                    </span>
                                    <span
                                      className="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <Link
                                        to={`/productdetail/${obj.id}`}
                                        className="quickview quickview-button"
                                      >
                                        <i className="pe-7s-bag"></i>
                                      </Link>
                                    
                                    </span>
                                  </div>
                                </div>
                                <div className="products-content">
                                  <div className="contents">
                                    <div className="rating">
                                      <div className="star star-0"></div>
                                      <span className="count">(0 review)</span>
                                    </div>

                                    <h3 className="product-title">
                                      <Link
                                        to={`/productdetail/${obj.id}`}
                                        className="details"
                                      >
                                        {obj.productname}
                                      </Link>
                                    </h3>
                                    <span className="price">
                                      {" "}
                                      {keys.currencyFormat(
                                        obj.online_sales_price
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
export default BestSellerProductList;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Sidebar = (props: any) => {
  const { data = [], showDropdown = false } = props;

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [category, setCategory] = useState<any[]>([]);
  const [isCollapsed, setIsCollapsed]= useState(true);

  useEffect(() => {
    // if(data)
    const min = data.reduce(
      (prev: any, curr: any) =>
        Number(
          prev.online_sales_price_with_tax < curr.online_sales_price_with_tax
            ? prev
            : curr
        ),
      0
    );
    const max = data.reduce(
      (prev: any, curr: any) =>
        Number(
          prev.online_sales_price_with_tax > curr.online_sales_price_with_tax
            ? prev
            : curr
        ),
      0
    );

    const distinctCategory = [...new Set(data.map((x: any) => x.category))];
    setCategory([...distinctCategory]);
    setMin(min);
    setMax(max);
  }, [data]);

  return (
    <div className="sidebar">
      {/* <aside className="widget">
        <h4 className="widget-title">FILTER BY PRICE</h4>
        <div className="options-price">
          <div id="price-slider"></div>
          <div className="price-range">
            <label>PRICE:</label>
            <input type="text" id="amount" />
          </div>
          <button className="filter-price-btn">Filter</button>
        </div>
      </aside> */}

      {showDropdown ?
      <div className="accordion accordion-flush" id="accordionExample" style={{border:0, marginBottom:10}}>
      <div className="accordion-item">
        <h2 className="accordion-header mt-0" id="headingOne">
          <button className={`accordion-button ${isCollapsed?``:`collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{fontSize:"medium", fontFamily:"inherit", letterSpacing:1}} onClick={()=>setIsCollapsed(!isCollapsed)}>
            Filters
          </button>
        </h2>
        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
          <aside className="widget">
            <h4 className="widget-title">CATEGORY</h4>
            <ul className="ul-sidebar">
              {category.map((x) => {
                const count = data.filter((z: any) => z.category === x);

                return (
                  <li key={Math.random()}>
                    <Link
                      to={
                        count.length > 0
                          ? `/productlisting/${count[0].categoryid}`
                          : "/productlisting"
                      }
                    >
                      {x}
                      <span>{`(${count.length})`}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </aside>
          <aside className="widget">
            <h4 className="widget-title">FILTER BY COLOR</h4>
            <ul className="ul-sidebar">
              <li>
                <a href="#">
                  Black
                  <span>(50)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Red
                  <span>(15)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Yellow
                  <span>(7)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Blue
                  <span>(25)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Green
                  <span>(13)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Gray
                  <span>(75)</span>
                </a>
              </li>
            </ul>
          </aside>
          </div>
        </div>
      </div>
      </div>
         :
        <>
          <aside className="widget">
            <h4 className="widget-title">CATEGORY</h4>
            <ul className="ul-sidebar">
              {category.map((x) => {
                const count = data.filter((z: any) => z.category === x);

                return (
                  <li key={Math.random()}>
                    <Link
                      to={
                        count.length > 0
                          ? `/productlisting/${count[0].categoryid}`
                          : "/productlisting"
                      }
                    >
                      {x}
                      <span>{`(${count.length})`}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </aside>
          <aside className="widget">
            <h4 className="widget-title">FILTER BY COLOR</h4>
            <ul className="ul-sidebar">
              <li>
                <a href="#">
                  Black
                  <span>(50)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Red
                  <span>(15)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Yellow
                  <span>(7)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Blue
                  <span>(25)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Green
                  <span>(13)</span>
                </a>
              </li>
              <li>
                <a href="#">
                  Gray
                  <span>(75)</span>
                </a>
              </li>
            </ul>
          </aside>
        </>
      }
    </div>
  );
};

export default Sidebar;

const Terms = () => {
  return (
    <div className="our-team-content">
      <div className="header-our-team">
        <div className="container">
          <h1>TERMS & CONDITIONS</h1>
        </div>
      </div>

      <hr className="gray-line" />
      <div className="the-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div className="team-item">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>{`The Website Owner, including subsidiaries and affiliates (“www.thewovenwonders.com” or “The Woven Wonders” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.`}</label>
                    </h4>
                  </div>
                  <br></br>
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    Welcome to <b>www.thewovenwonders.com</b>. If you continue
                    to browse and use this website you are agreeing to comply
                    with and be bound by the following terms and conditions of
                    use, which together with our privacy policy govern
                    www.thewovenwonders.com's relationship with you in relation
                    to this website.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {" "}
                      The term The Woven Wonders ‘www.thewovenwonders.com’ or
                      'us' or 'we' refers to the owner of the website whose
                      registered office is - 7 Nilkanth Duplex, Gotri Road,
                      Vadodara, Gujarat - 390021
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {" "}
                      The term 'you' refers to the user or viewer of our
                      website.
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {`The use of this website is subject to the following terms of use:

The content of the pages of this website is for your general information and use only. It is subject to change without notice`}
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {`Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law
Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements`}
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {`This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions`}
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {`All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website
Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence`}
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {`From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website. We have no responsibility for the content of the linked website`}
                    </p>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <br></br>
                    <p>
                      {`You may not create a link to this website from another website or document without The Woven Wonders prior written consent
Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;

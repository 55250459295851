import { keys } from "../../config/keys";
import axios from "../../common/axios";
import { _dispatchPayload } from "./dispatchPayload";
import { SALES_LIST } from "../constTypes";
import dayjs from "dayjs";

export const get_last_invoiceno = (callback) => async (dispatch) => {
  await axios
    .get(
      `${keys.ServicePath}/api/common/latest_invoice_number/sales_master_online`
    )
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const save_sales = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/sales/online`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_sales_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/sales`)
    .then((res) => {
      dispatch(_dispatchPayload(SALES_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_sales_by_id = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/sales/${id}`)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_online_sales_by_id = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/sales/online/${id}`)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const create_rp_order = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/payment/orders`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const verify_rp_order = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/payment/success`, data)
    .then((res) => {
      // console.log("verify_rp_order", res.data);
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const add_shipment = (data, callback) => async (dispatch) => {
  let shipdata = {
    order_id: data.razorpayOrderId,
    order_date: dayjs(Date()).format(keys.dbTimeFormat),
    pickup_location: "Primary",
    channel_id: "",
    comment: "Reseller: TWW",
    billing_customer_name: data.customername,
    billing_last_name: "",
    billing_address: data.address,
    billing_address_2: data.address1,
    billing_city: data.state,
    billing_pincode: data.pincode,
    billing_state: data.state,
    billing_country: data.country,
    billing_email: data.email,
    billing_phone: data.mobile,
    shipping_is_billing: true,
    shipping_customer_name: "",
    shipping_last_name: "",
    shipping_address: "",
    shipping_address_2: "",
    shipping_city: "",
    shipping_pincode: "",
    shipping_country: "",
    shipping_state: "",
    shipping_email: "",
    shipping_phone: "",
    order_items: data.salestransaction.map((x) => ({
      name: x.productname,
      sku: x.sku,
      units: x.quantity,
      selling_price: x.itemtotalwithouttax,
      discount: x.discounts,
      tax: x.taxes,
      hsn: 52091112
    })),
    payment_method: "Prepaid",
    shipping_charges: data.shipping,
    giftwrap_charges: 0,
    transaction_charges: 0,
    total_discount: data.discounts,
    sub_total: data.nettotal,
    length: 10,
    breadth: 15,
    height: 20,
    weight: 2.5
  };

  await axios
    .post(`${keys.ServicePath}/api/sales/shipping`, shipdata)
    .then((res) => {
      // console.log("verify_rp_order", res.data);
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });

  // const targetUrl =
  //   "https://apiv2.shiprocket.in/v1/external/orders/create/adhoc";
  // const proxyUrl = "https://cors-anywhere.herokuapp.com/";

  // console.log(shipdata);
  // console.log(JSON.stringify(shipdata));

  // await fetch(proxyUrl + targetUrl, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization:
  //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2NjI4MjUsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE1MzU0NDkxLCJqdGkiOiI0N0I3RW92Wk44WTNCQ3dzIiwiaWF0IjoxNzE0NDkwNDkxLCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxNDQ5MDQ5MSwiY2lkIjozNjgxODcwLCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.5KdsYBNNTf4WItJ0RR9WDtMsVMdcgLyxEQp73j9nYR0"
  //   },
  //   body: shipdata,
  //   redirect: "follow"
  // })
  //   .then((res) => res.json())
  //   .then((data) => {
  //     console.log(data);
  //     if (callback?.onSuccess) callback.onSuccess(data);
  //   })
  //   .catch((e) => {
  //     console.log("shiperror", e);
  //     if (callback?.onError) callback?.onError(e);
  //   });

  // await axios
  //   .post(
  //     "https://apiv2.shiprocket.in/v1/external/orders/create/adhoc",
  //     shipdata,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization:
  //           "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2NjI4MjUsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE1MzU0NDkxLCJqdGkiOiI0N0I3RW92Wk44WTNCQ3dzIiwiaWF0IjoxNzE0NDkwNDkxLCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxNDQ5MDQ5MSwiY2lkIjozNjgxODcwLCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.5KdsYBNNTf4WItJ0RR9WDtMsVMdcgLyxEQp73j9nYR0"
  //       }
  //     }
  //   )
  //   .then((res) => {
  //     if (callback?.onSuccess) callback.onSuccess(res.data);
  //   })
  //   .catch((e) => {
  //     console.log("shiperror", e);
  //     if (callback?.onError) callback?.onError(e);
  //   });
};

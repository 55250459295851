import { createSlice } from "@reduxjs/toolkit";
import { CATEGORY_BY_ID, CATEGORY_LIST } from "../../constTypes";

const initialState = {
  status: "idle",
  fields: [
    {
      field: "category",
      headerName: "Category",
      width: 100,
      align: "left",
      editable: true
    }
  ],
  // tags: ["Lux", "Hot", "Silk", "Linen", "Featured"],
  categoryArray: []
};

const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  reducers: {}
});

export const category_list = (state = [], action) => {
  switch (action.type) {
    case CATEGORY_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const category_by_id = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_BY_ID:
      return action.payload;
    default:
      return state;
  }
};

export default categorySlice.reducer;

// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";

import * as productAttributeReducer from "./products/productAttributeSlice";
import * as adminReducer from "./userSlice";
import * as vendorReducer from "./vendorSlice";
import * as categoryReducer from "./productCategory";
import * as purchaseReducer from "./purchase";
import * as customerReducer from "./customer";
import * as salesReducer from "./sales";
import * as cartReducer from "./cart";
import * as commonReducer from "./common"
// ==============================|| COMBINE REDUCERS ||============================== //

const rootReducer = combineReducers({
  menu,
  ...vendorReducer,
  ...productAttributeReducer,
  ...categoryReducer,
  ...adminReducer,
  ...purchaseReducer,
  ...customerReducer,
  ...salesReducer,
  ...cartReducer,
  ...commonReducer
});
// const reducers = combineReducers({ menu});
export default rootReducer;

import { useState } from "react";
import { dispatch } from "../../store";
import { customerAction } from "../../store/actions";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    mobileno: "",
    showOTP: false,
    otp: "",
    userOTP: ""
  });

  const RequestOTP = () => {
    dispatch(
      customerAction.request_otp(data.mobileno, {
        onSuccess: (res: any) =>
          setData(prev => ({
            ...prev,
            showOTP: true,
            otp: res.otp
          })),
        onError: (err: any) =>
          setData(prev => ({
            ...prev,
            showOTP: false
          }))
      })
    );
  };

  const VerifyOTP = () => {
    if (Number(data.otp) === Number(data.userOTP)) {
      dispatch(
        customerAction.login(
          { mobile: data.mobileno },
          {
            onSuccess: (res: any) => {
              navigate("/productlisting");
            }
          }
        )
      );
    } else {
      alert("Invalid");
    }
    // dispatch(
    //   customerAction.request_otp(data.mobileno, {
    //     onSuccess: (res: any) =>
    //       setData(prev => ({
    //         ...prev,
    //         showOTP: true,
    //         otp: res
    //       })),
    //     onError: (err: any) =>
    //       setData(prev => ({
    //         ...prev,
    //         showOTP: false
    //       }))
    //   })
    // );
  };

  return (
    <div className="log-reg-content">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 col-sm-12">
            <form action="#" className="login-form">
              <h3>LOGIN</h3>
              <label>Mobile No*</label>
              <input
                className="input-form"
                type="text"
                name="mobileno"
                onChange={e =>
                  setData(prev => ({
                    ...prev,
                    [e.target.name]: e.target.value
                  }))}
              />
              {data.showOTP &&
                <OTPInput
                  value={data.userOTP}
                  onChange={val =>
                    setData(prev => ({
                      ...prev,
                      userOTP: val
                    }))}
                  numInputs={6}
                  renderSeparator={<span>{` `}</span>}
                  renderInput={props =>
                    <input
                      {...props}
                      className="input-form"
                      style={{ width: "5rem", marginRight: "22px" }}
                    />}
                />}
              {data.showOTP
                ? <button
                    className="submit-btn"
                    type="button"
                    onClick={VerifyOTP}
                  >
                    {`VERIFY OTP`}
                  </button>
                : <button
                    className="submit-btn"
                    type="button"
                    onClick={RequestOTP}
                  >
                    {`SEND OTP`}
                  </button>}

              {/* <label>YOUR PASSWORD*</label>
              <input
                className="input-form"
                type="password"
                id="your_password"
              />
              <button className="submit-btn" type="submit">
                LOGIN
              </button> */}
              {/* <div className="checkbox">
                <label>
                  <input type="checkbox" /> REMEMBER ME?
                </label>
              </div> */}
              {/* <a href="#">LOST YOUR PASSWORD?</a> */}
            </form>
          </div>
          {/* <div className="col-md-6">
            <form action="#" className="register-form">
              <h3>REGISTER</h3>
              <h5>IF YOU DON’T HAVE AN ACCOUNT, REGISTER NOW!</h5>
              <div className="checkbox">
                <label>
                  <input type="checkbox" /> I ACCEPT THE TERMS AND CONDITIONS,
                  INCLUDING THE PRIVACY POLICY
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" /> I WANT TWW TO SEND ME NEWS AND
                  UPDATES BY EMAIL
                </label>
              </div>
              <button className="submit-btn" type="submit">
                REGISTER
              </button>
            </form>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Auth;

import { GET_CITIES, GET_COUNTRIES, GET_STATES } from "../constTypes";

export type Cities = {
    value: string | number;
    label: string;
    state_id: string | number;
};

export type Countries = {
    value: string | number;
    label: string;
    shippingcharge: number
};

export type States = {
    value: string | number;
    label: string;
    country_id?: string | number;
};
export type Common = {
    states: States[];
    cities: Cities[];
    countries: Countries[];
};

const initialState: Common = {
    states: [],
    cities: [],
    countries: []
};


export type Actions = {
    type: string;
    payload: any;
};

export const geo_location_list = (state = initialState, action: Actions) => {
    switch (action.type) {
        case GET_STATES:
            return { ...state, states: action.payload || [] };
        case GET_CITIES:
            return { ...state, cities: action.payload || [] };
        case GET_COUNTRIES:
            return { ...state, countries: action.payload || [] };
        default:
            return state;
    }
};
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  get_online_sales_by_id,
  get_sales_by_id
} from "../../store/actions/sales";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { keys } from "../../config/keys";
import { SalesFormData } from ".";
import { useParams } from "react-router";

const initialValue: SalesFormData = {
  shippingaddress: false,
  requestOTP: false,
  otp: "",
  otpVerified: false,
  mobile: "",
  address: "",
  address1: "",
  city: "",
  cityid: 0,
  customername: "",
  id: 0,
  pincode: "",
  stateid: 0,
  statename: "",
  email: "",
  shippedby: "",
  shippingrefrenceno: "",
  producttotal: 0,
  discounts: 0,
  taxes: 0,
  nettotal: 0,
  paymentmethod: "",
  invoiceno: "",
  orderdate: "",
  productdetails: [],
  countryid: 0,
  countryname: "",
  shipping: 0,
  orderId: "",
  paymentId: ""
};

const Invoice = React.forwardRef((props: any, ref) => {
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  //   const onPrintInvoice = useReactToPrint({
  //     content: () => ref.current
  //   });

  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef<any>(null);
  const componentRef = useRef(null);
  // useEffect(() => {
  //   if (isPrinting && promiseResolveRef.current) {
  //     promiseResolveRef.current();
  //   }
  // }, [isPrinting]);

  // const onPrintInvoice = useReactToPrint({
  //   content: () => ref?.current,
  //   onBeforeGetContent: () => {
  //     return new Promise((resolve) => {
  //       //console.log(resolve);
  //       promiseResolveRef.current = resolve;
  //       setIsPrinting(true);
  //     });
  //   },
  //   onAfterPrint: () => {
  //     promiseResolveRef.current = null;
  //     setIsPrinting(false);
  //   }
  // });

  const [data, setData] = useState<SalesFormData>(initialValue);
  const _get_sales_by_id = (id: number) =>
    dispatch(
      get_online_sales_by_id(id, {
        onSuccess: async (res: any) => {
          if (res[0].length > 0) {
            //console.log(res[0], res[1]);
            await setData({
              ...res[0][0],
              productdetails: res[1]
            });
          }
        }
      })
    );

  useEffect(() => {
    if (Number(id) > 0) _get_sales_by_id(Number(id));
  }, [id]);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className="btn btn-warning no_print btn-sm ms-2">
            <i className="fa fa-print" />
            <span className="ml-2">{"Print"}</span>
          </button>
        )}
        content={() => componentRef.current}
      />
      <div ref={componentRef} className="m-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              width={150}
            />
          </div>
          <div className="h4 py-2 my-4 border-bottom border-top col-12 justify-ceontent-center text-center text-uppercase">
            {`Tax Invoice`}
          </div>

          <div className="col-4 px-4">
            <div className="row ">
              <div className="col-12 text-uppercase fw-semibold">
                {"Shipping Address:"}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">{data?.customername}</div>
              <div className="col-12">{data?.address}</div>
              <div className="col-12">{data?.pincode}</div>
              <div className="col-12">{data?.mobile}</div>
            </div>
          </div>
          <div className="col-4 px-4 border-start border-2 border-dashed border-secondary">
            <div className="row">
              <div className="col-12 text-uppercase fw-semibold">
                {"Sold By:"}
              </div>
            </div>
            <div className="row pe-3">
              <div className="col-12 d-flex justify-content-end">{`The Woven Wonders`}</div>
              <div className="col-12 d-flex justify-content-end">
                {`7 Nilkanth Duplex`}
              </div>
              <div className="col-12 d-flex justify-content-end">{`Behind Mothers School, 
                Gotri Road`}</div>
              <div className="col-12 d-flex justify-content-end">{`Near Balaji Restaurant`}</div>
              <div className="col-12 d-flex justify-content-end">{`Vadodara 390021`}</div>
              <div className="col-12 d-flex justify-content-end">{`Gujarat, India`}</div>
              <div className="col-12 d-flex justify-content-end">
                {data?.pincode}
              </div>
              <div className="col-12 d-flex justify-content-end">
                {data?.mobile}
              </div>
            </div>
          </div>
          <div className="col-4 px-4 border-start border-2 border-dashed border-secondary">
            <div className="row d-flex flex-row justify-content-center">
              <div className="col-12 text-uppercase fw-semibold">
                {"Invoice Details:"}
              </div>
            </div>
            <div className="row d-flex flex-row justify-content-center">
              <div className="col-12">
                <b>{`Invoice #: `}</b>
                {data?.invoiceno}
              </div>
              <div className="col-12">
                <b>{`Invoice Date: `}</b>
                {keys.shortDate(data?.orderdate)}
              </div>
              <div className="col-12">
                <b>{`Channel: `}</b>
                {`The Woven Wonders`}
              </div>
              <div className="col-12">
                <b>{`Shipped By: `}</b>
                {`${data?.shippedby || " - "}`}
              </div>
              <div className="col-12">
                <b>{`AWB Ref #: `}</b>
                {`${data?.shippingrefrenceno || " - "}`}
              </div>
              <div className="col-12">
                <b>{`Payment: `}</b>
                {`${data?.paymentmethod || " - "}`}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th className="text-uppercase">{`Sr. #`}</th>
                  <th className="text-uppercase">{`Product Name`}</th>
                  <th className="text-uppercase">{`Qty`}</th>
                  <th className="text-uppercase">{`Price`}</th>
                  <th className="text-uppercase">{`Discount`}</th>
                  <th className="text-uppercase">{`Taxable Value`}</th>
                  <th className="text-uppercase">{`Tax`}</th>
                  <th className="text-uppercase">{`Total (inc. GST)`}</th>
                </tr>
              </thead>
              <tbody>
                {data?.productdetails?.map((item: any, idx: number) => (
                  <tr key={Math.random()}>
                    <td>{idx + 1}</td>
                    <td>{item.productname}</td>
                    <td>{item.qty}</td>
                    <td className="text-end">
                      {keys.decimalNumber(item.price)}
                    </td>
                    <td className="text-end">
                      {keys.decimalNumber(item.discount)}
                    </td>
                    <td className="text-end">
                      {keys.decimalNumber(item.taxableamount)}
                    </td>
                    <td className="text-end">{keys.decimalNumber(item.tax)}</td>
                    <td className="text-end">
                      {keys.decimalNumber(item.nettotal)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="text-uppercase fw-bold border-top">
                <tr key={Math.random()}>
                  <td colSpan={7}>{`Shipping`}</td>

                  <td className="text-end">
                    {data.shipping > 0
                      ? keys.decimalNumber(data?.shipping)
                      : `FREE SHIPPING`}
                  </td>
                
                </tr>
                <tr key={Math.random()}>
                  <td colSpan={2}>{`Total`}</td>
                  <td></td>
                  <td className="text-end"></td>
                  <td className="text-end"></td>
                  <td className="text-end">
                    {keys.decimalNumber(data?.producttotal)}
                  </td>
                  <td className="text-end">
                    {keys.decimalNumber(data?.taxes)}
                  </td>
                  <td className="text-end">
                    {keys.decimalNumber(data?.nettotal)}
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className="h4 border-bottom border-top col-12 text-end text-uppercase fw-small ">
              {`Net Total: ${keys.currencyFormat(data?.nettotal)}`}
            </div>
          </div>
        </div>
        <div className="row mt-4 border-top text-body-tertiary">
          {`This is computer generated invoice signature not required.`}
        </div>
      </div>
    </>
  );
});

export default Invoice;

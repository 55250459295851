import * as actionTypes from "../../actions/cart/types";

const initialState = {
  item: [],
  itemPriceTotal: 0,
  totalItem: 0,
  totalQuantity: 0
};

export const cart = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CART_ITEM:
      let newState = { ...state };
      let itemIndex = newState.item.findIndex(
        (x) => x.productid === action.data.productid
      );
      let item = [
        ...newState.item.filter((x) => x.productid !== action.data.productid)
      ];
      let item_exists = newState.item.find(
        (x) => x.productid === action.data.productid
      );
      if (item_exists) {
        let update_item = {
          ...item_exists,
          quantity: action.data.quantity,
          itemtotal:
            item_exists.online_sales_price_with_tax * action.data.quantity
        };
        const producttotalwithouttax =
          update_item.online_sales_price * action.data.quantity;
        const producttotalwithtax =
          update_item.online_sales_price_with_tax * action.data.quantity;
        update_item.itemtotal = producttotalwithtax;
        update_item.itemtotalwithouttax = producttotalwithouttax;
        update_item.producttotal = producttotalwithouttax;
        update_item.taxes = producttotalwithtax - producttotalwithouttax;
        update_item.discounts = 0;
        update_item.nettotal = producttotalwithtax;

        item.splice(itemIndex, 0, update_item);
        const nData = {
          ...newState,
          item: [...item],
          itemPriceTotal: newState.item.reduce(
            (a, b) => a + (b.itemtotal || 0),
            0
          ),
          totalItem: newState.item.length,
          totalQuantity: newState.item.reduce(
            (a, b) => a + (b.quantity || 0),
            0
          )
        };

        return {
          ...nData,
          itemPriceTotal: nData.item.reduce(
            (a, b) => a + (b.itemtotal || 0),
            0
          ),
          totalItem: nData.item.length,
          totalQuantity: nData.item.reduce((a, b) => a + (b.quantity || 0), 0),
          producttotal: nData.item.reduce(
            (a, b) => a + (parseInt(b.producttotal) || 0),
            0
          ),
          taxes: nData.item.reduce((a, b) => a + (parseInt(b.taxes) || 0), 0),
          discounts: 0,
          nettotal: nData.item.reduce(
            (a, b) => a + (parseInt(b.nettotal) || 0),
            0
          )
        };
      } else {
        let data = action.data;
        const producttotalwithouttax = data.online_sales_price * data.quantity;
        const producttotalwithtax =
          data.online_sales_price_with_tax * data.quantity;

        data.itemtotal = producttotalwithtax;
        data.itemtotalwithouttax = producttotalwithouttax;
        data.producttotal = producttotalwithouttax;
        data.taxes = producttotalwithtax - producttotalwithouttax;
        data.discounts = 0;
        data.nettotal = producttotalwithtax;

        const fItem = [...state.item, data];
        return {
          ...state,
          item: [...fItem],
          itemPriceTotal:
            state.item.reduce((a, b) => a + (b.itemtotal || 0), 0) +
            data.itemtotal,
          totalItem: state.item.length + 1,
          totalQuantity:
            state.item.reduce((a, b) => a + (parseInt(b.quantity) || 0), 0) +
            action.data.quantity,
          producttotal:
            state.item.reduce(
              (a, b) => a + (parseInt(b.producttotal) || 0),
              0
            ) + data.producttotal,
          taxes:
            state.item.reduce((a, b) => a + (parseInt(b.taxes) || 0), 0) +
            data.taxes,
          discounts: 0,
          nettotal:
            state.item.reduce((a, b) => a + (parseInt(b.nettotal) || 0), 0) +
            data.nettotal
        };
      }

    case actionTypes.DELETE_CART_ITEM:
      let tmpItem = [...state.item];
      tmpItem = tmpItem.filter((x) => x.productid !== action.data.productid);
      return {
        ...state,
        item: [...tmpItem],
        itemPriceTotal: tmpItem.reduce((a, b) => a + (b.itemtotal || 0), 0),
        totalItem: tmpItem.length,
        totalQuantity: tmpItem.reduce(
          (a, b) => a + (parseInt(b.quantity) || 0),
          0
        ),
        producttotal: tmpItem.reduce(
          (a, b) => a + (parseInt(b.producttotal) || 0),
          0
        ),
        taxes: tmpItem.reduce((a, b) => a + (parseInt(b.taxes) || 0), 0),
        discounts: 0,
        nettotal: tmpItem.reduce((a, b) => a + (parseInt(b.nettotal) || 0), 0)
      };

    case actionTypes.EMPTY_CART_ITEM:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

import { keys } from "../../config/keys";
import axios from "../../common/axios";
import { _dispatchPayload } from "./dispatchPayload";
import { CUSTOMER, CUSTOMER_LIST, WISHLIST } from "../constTypes";
import { EMPTY_CART_ITEM } from "./cart/types";

export const get_customer_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/customer`)
    .then((res) => {
      dispatch(_dispatchPayload(CUSTOMER_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_customer_by_mobile =
  (mobileno, callback) => async (dispatch) => {
    await axios
      .get(`${keys.ServicePath}/api/customer/${mobileno}`)
      .then((res) => {
        dispatch(_dispatchPayload(CUSTOMER, res.data.rows));
        if (callback?.onSuccess) callback.onSuccess(res.data);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
      });
  };

export const request_otp = (mobileno, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/customer/otp`, { mobileno })
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const login = (loginData, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/auth/customer_login`, loginData)
    .then((res) => {
      if (!res.data?.success) {
        if (callback?.onError) callback?.onError(res.data);
      } else {
        if (callback?.onSuccess) {
          dispatch(_dispatchPayload(CUSTOMER, res.data.user));
          callback?.onSuccess(res);
        }
      }
    })
    .catch((e) => {});
};

export const logout = () => async (dispatch) => {
  dispatch(_dispatchPayload(CUSTOMER,null));
  dispatch(_dispatchPayload(WISHLIST,[]));
  dispatch(_dispatchPayload(EMPTY_CART_ITEM,[]))
};

export const add_wishlist = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/customer/add_wishlist`, { ...data })
    .then((res) => {
      dispatch(_dispatchPayload(WISHLIST, res.data));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_wishlist = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/customer/delete_wishlist`, { ...data })
    .then((res) => {
      dispatch(_dispatchPayload(WISHLIST, res.data));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_customer_wishlist = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/customer/get_customer_wishlist`, { ...data })
    .then((res) => {
      dispatch(_dispatchPayload(WISHLIST, res.data));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

import { cartAction, customerAction } from "../../store/actions";
import { useEffect } from "react";

const Wishlist = () => {
  const customer  = useSelector((obj: any) => obj.customer);
  const wishlist  = useSelector((obj: any) => obj.wishlist);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const get_customer_wishlist = () => {    
    dispatch(customerAction.get_customer_wishlist({customerid: customer?.id || 0}));
  };

  useEffect(()=>{
get_customer_wishlist();
  },[])


  return (
    <div className="main">
      <div className="container">
        <div className="header-page">
          <h1>MY WISHLIST</h1>
        </div>

        <div className="main-content">
          {wishlist.length <= 0 && (
            <div className="row mgt-70">
              <div className="col-md-12">
                <h3>{`WISHLIST IS EMPTY`}</h3>
              </div>
              <div className="col-md-12 shop-shipping">
                <button
                  className="update-totals-btn"
                  onClick={() => navigate("/productlisting")}
                >{`Continue Shopping`}</button>
              </div>
            </div>
          )}
          {wishlist.length > 0 && (
            <form className="shop-form">
              <div className="table-responsive d-none d-sm-block">
                <table
                  className="shop-table table table table-sm"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Product</th>
                     </tr>
                  </thead>
                  <tbody>
                    {wishlist?.map((x: any) => (
                      <tr className="cart_item" key={Math.random()}>
                        <td className="product-remove">
                          <button
                            className="remove"
                            type="button"
                            onClick={() =>
                              dispatch(customerAction.delete_wishlist(x))
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 0
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                        {/* <td className="product-thumbnail">
                          {x.product_images?.length > 0 ? (
                            <img
                              className="img-responsive"
                              src={`${keys.ServicePath}/${x.product_images[0].filepath}`}
                              alt="img"
                              width={50}
                            />
                          ) : (
                            <img
                              className="img-responsive"
                              src={require("../../assets/images/noImage.jpg")}
                              width={50}
                              alt="img"
                            />
                          )}
                        
                        </td> */}
                        <td className="product-name">
                          <Link to={`/productdetail/${x.productid}`}>
                            {x.productname}
                          </Link>
                        </td>
                    
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
             
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Wishlist;

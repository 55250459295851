import { BLOGS, CUSTOMER, CUSTOMER_LIST, WISHLIST } from "../constTypes";

export const customer_list = (state = [], action) => {
  switch (action.type) {
    case CUSTOMER_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const customer = (state = [], action) => {
  switch (action.type) {
    case CUSTOMER:
      return action.payload || [];
    default:
      return state;
  }
};

export const wishlist = (state = [], action) => {
  switch (action.type) {
    case WISHLIST:
      return [...action.payload] || [];
    default:
      return state;
  }
};


export const blogs = (state = [], action) => {
  switch (action.type) {
    case BLOGS:
      return [...action.payload] || [];
    default:
      return state;
  }
};
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { cartAction, productAction } from "../../store/actions";
import { keys } from "../../config/keys";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/styles.css";

const ProductDetail = () => {
  const { productId } = useParams();

  const dispatch = useDispatch<any>();
  const get_product_detail_by_id = useSelector(
    (obj: any) => obj.get_product_detail_by_id
  );
  const _get_product_detail = (pid: number) =>
    dispatch(productAction.getProductDetailById(pid));
  useEffect(() => {}, []);

  useEffect(() => {
    if (Number(productId) > 0) _get_product_detail(Number(productId));
  }, [productId]);

  useMemo(() => {
    if (Number(productId) > 0) _get_product_detail(Number(productId));
  }, [productId]);

  const [qty, setQty] = useState(1);

  const addCart = (item: any) => {
    const { attribute_values, ...others } = item;
    let p = {
      ...others,
      quantity: Number(qty)
    };
    dispatch(cartAction.addCartItem(p));
  };

  const [bigImageSrc, setBigImageSrc] = useState("");

  useEffect(() => {
    try {
      get_product_detail_by_id?.product_images?.length > 0
        ? setBigImageSrc(
            `${keys.ServicePath}/${get_product_detail_by_id?.product_images[0].filepath}`
          )
        : setBigImageSrc("");
      // const script = document.createElement("script");
      // script.src = "../../assets/scripts/functions.js";
      // console.log(script.src);
      // document.head.appendChild(script);
      // return () => {
      //   document.head.removeChild(script);
      // };
      // var product_showcase_thumb = $(".gallery .previews .box-content");
      // console.log("get_product_detail_by_id",product_showcase_thumb)
      // product_showcase_thumb.owlCarousel({
      //   margin: 0,
      //   smartSpeed: 1000,
      //   dots: false,
      //   responsive: {
      //     0: {
      //       items: 3
      //     },
      //     480: {
      //       items: 4
      //     },
      //     768: {
      //       items: 5
      //     },
      //     1200: {
      //       items: 6
      //     }
      //   }
      // });
      // $(".gallery .previews .next").click(function () {
      //   product_showcase_thumb.trigger("next.owl.carousel");
      // });
      // $(".gallery .previews .prev").click(function () {
      //   product_showcase_thumb.trigger("prev.owl.carousel");
      // });
    } catch (e) {}
  }, [get_product_detail_by_id]);

  const pr = { width: 400, height: "auto", zoomWidth: 500 };
  const [open, setOpen] = React.useState(false);

  return (
    <div className="main">
      <div className="container">
        <div className="main-content">
          <div className="product">
            <div className="row">
              <div className="col-md-6">
                <div className="images">
                  <div id="product-showcase">
                    <div className="gallery">
                      <div className="full">
                        {bigImageSrc !== "" && <img src={bigImageSrc} onClick={()=>setOpen(true)} style={{cursor:"pointer"}}/>}
                        {/* {bigImageSrc !== "" && (
                          <InnerImageZoom
                            src={bigImageSrc}
                            hasSpacer={true}
                            zoomType="hover"
                            zoomPreload={true}
                          />
                        )} */}
                        {/* {bigImageSrc !== "" && (
                          <ReactFancyBox
                            thumbnail={bigImageSrc}
                            image={bigImageSrc}
                          />
                        )} */}
                      </div>
                      <div className="previews">
                        <div className="box-content">
                          <OwlCarousel
                            className="owl-theme"
                            margin={0}
                            smartSpeed={1000}
                            dots={true}
                          >
                            {get_product_detail_by_id?.product_images?.map(
                              (item: any) => (
                                <img
                                  key={Math.random()}
                                  data-full={`${keys.ServicePath}/${item?.filepath}`}
                                  src={`${keys.ServicePath}/${item?.filepath}`}
                                  width={85}
                                  onClick={() =>
                                    setBigImageSrc(
                                      `${keys.ServicePath}/${item?.filepath}`
                                    )
                                  }
                                />
                              )
                            )}
                          </OwlCarousel>
                        </div>
                        {/* <div className="nav">
                          <span className="prev">
                            <i className="fa fa-angle-left"></i>
                          </span>
                          <span className="next">
                            <i className="fa fa-angle-right"></i>
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {/* <nav className="breadcrumb">
                  <a href="index.html">HOME</a>{" "}
                  <i className="fa fa-angle-right"></i>{" "}
                  <a href="shop-fullwidth.html">MAIN MENU</a>{" "}
                  <i className="fa fa-angle-right"></i>{" "}
                  <span>PIG ON A STICK</span>
                </nav> */}
                <div className="summary">
                  <h2 className="product-name">
                    {`${get_product_detail_by_id?.productname}`}
                  </h2>
                  {/* <div className="product-statistic">
                    <span className="review-count">30 REVIEWS</span>
                    <span
                      className="review-star-read star-rating"
                      data-score="4"
                    >
                      {[1, 2, 3, 4, 5].map((x) => (
                        <i
                          className="fa fa-fw fa-star"
                          title="good"
                          data-score={x}
                          key={Math.random()}
                        ></i>
                      ))}
                    </span>
                  </div> */}
                  <div className="price">
                    {keys.currencyFormat(
                      get_product_detail_by_id?.online_sales_price_with_tax
                    )}
                  </div>
                  <div className="short-desc">
                    {get_product_detail_by_id?.categoryDetail}
                    <br />
                    {get_product_detail_by_id?.productDetail}
                  </div>
                  {get_product_detail_by_id?.stock > 0 &&
                    get_product_detail_by_id?.stock < 6 && (
                      <h6 className="text-danger ">{`ONLY ${get_product_detail_by_id?.stock} LEFT IN STOCK`}</h6>
                    )}
                  <div className="product-action">
                    {get_product_detail_by_id?.stock > 0 ? (
                      <div className="clearfix">
                        <div className="quantity">
                          <button
                            className="minus-btn"
                            disabled={qty <= 1}
                            onClick={() => setQty((prev) => prev - 1)}
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                          <input
                            type="text"
                            value={qty}
                            onChange={(e) => {
                              if (
                                Number(e.target.value) <=
                                get_product_detail_by_id?.stock
                              ) {
                                setQty(Number(e.target.value));
                              }
                            }}
                          />
                          <button
                            className="plus-btn"
                            disabled={
                              get_product_detail_by_id?.stock - qty <= 0
                            }
                            onClick={() => setQty((prev) => prev + 1)}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>
                        <button
                          type="button"
                          className="add-to-cart-btn"
                          onClick={() => addCart(get_product_detail_by_id)}
                        >
                          {`ADD TO CART`}
                        </button>
                      </div>
                    ) : (
                      <div
                        className="price"
                        style={{ fontSize: 20 }}
                      >{`OUT OF STOCK`}</div>
                    )}
                    <a href="#" className="wishlist-link">
                      <i className="pe-7s-like"></i>ADD TO WISHLIST
                    </a>
                  </div>
                  <ul className="ul-product">
                    <li>{`SKU: ${get_product_detail_by_id?.sku}`}</li>
                    <li>
                      {`Categories:`}
                      <Link
                        to={`/productlisting/${get_product_detail_by_id.categoryid}`}
                      >{`${get_product_detail_by_id.category}`}</Link>
                    </li>
                    <li>Tags: {`${get_product_detail_by_id.tags || ""}`}</li>
                  </ul>
                  <div className="share-this">
                    <h4>SHARE THIS:</h4>
                    <ul className="list-social-2">
                      <li>
                        <FacebookShareButton
                          url={`${keys.authorizedURL}/productdetail/${productId}`}
                        >
                          <FacebookIcon size={36} borderRadius={5} />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={`${keys.authorizedURL}/productdetail/${productId}`}
                        >
                          <TwitterIcon size={36} borderRadius={5} />
                        </TwitterShareButton>
                      </li>
                      {/* <li>
                      <InstapaperShareButton url={`${keys.authorizedURL}/productdetail/${productId}`}>
                          <InstapaperIcon size={36} borderRadius={5} />
                          </InstapaperShareButton>
                      
                      </li> */}
                      <li>
                        <WhatsappShareButton
                          url={`${keys.authorizedURL}/productdetail/${productId}`}
                        >
                          <WhatsappIcon size={36} borderRadius={5} />
                        </WhatsappShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={get_product_detail_by_id?.product_images?.map(
          (item: any) => (
            {src: `${keys.ServicePath}/${item?.filepath}`}
          )
        )}
        plugins={[Zoom,Fullscreen]}
      />
            {/* <div id="vertical-tabs" className="product-tabs vertical-tabs">
            <ul className="resp-tabs-list hor_1">
              <li>DESCRIPTION</li>
              <li>ADDITIONAL INFORMATION</li>
              <li>REVIEWS (10)</li>
            </ul>
            <div className="resp-tabs-container hor_1">
              <div>
                Cras ut magna quis metus tristique vulputate. Ut a sapien
                scelerisque, fermentum lorem a, aliquet mi. Ut lobortis lorem
                nisl, vel euismod ligula ornare quis. In consectetur elit sed
                leo fringilla, a placerat ipsum mollis. Proin suscipit metus
                vitae lectus malesuada scelerisque. Quisque interdum malesuada
                nisi non accumsan. Suspendisse eget lorem libero. Pellentesque
                mauris risus, cursus eget aliquet quis, iaculis vel purus. Nam
                in ligula vel mi interdum tincidunt sit amet eget leo. Phasellus
                dapibus augue a lorem luctus aliquet. Duis consectetur eros
                arcu, eget consequat orci mattis non.Nam nec ex maximus sem
                egestas elementum. Duis nisl arcu, sodales ut porttitor at,
                tincidunt vitae risus. Suspendisse eu nunc ligula.
              </div>
              <div>
                Lorem consectetur adipiscing elit. Vestibulum nibh urna, euismod
                ut ornare non, volutpat vel tortor. Integer laoreet placerat
                suscipit. Sed sodales scelerisque commodo. Nam porta cursus
                lectus. Proin nunc erat, gravida a facilisis quis, ornare id
                lectus. Proin consectetur nibh quis.
              </div>
              <div>
                <div className="comments-area">
                  <div className="comment-account media">
                    <div className="media-left">
                      <a href="#">
                        <img
                          className="avatar"
                          src="assets/images/cmt-avatar-1.jpg"
                          alt="img"
                        />
                      </a>
                      <h4>ANNA PANDORA</h4>
                      <h5>Stylist</h5>
                    </div>
                    <div className="media-body">
                      Nulla mattis mi quis elit porttitor. Ut ut laoreet justo.
                      Suspendisse nulla ligula, aliquet sed cursus ut, sodales
                      sit amet metus. Proin sollicitudin, urna nec placerat
                      faucibus, ex elit mattis nulla, sed placerat libero dui
                      sem. Etiam non auctor odio. Cras posuere ante vitae
                      molestie. Nunc imperdiet viverra nunc, eget aliquet diam.
                    </div>
                  </div>
                  <p className="comment-count">5 COMMENTS</p>
                  <ol className="comment-list media-list">
                    <li className="comment parent">
                      <article className="comment-body media">
                        <div className="media-left">
                          <a href="#">
                            <img
                              className="avatar"
                              src="assets/images/cmt-avatar-2.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <div className="comment-content">
                            Nulla mattis mi quis elit porttitor gravida. Ut ut
                            laoreet justo. Suspendisse nulla ligula
                          </div>
                          <div className="comment-info">
                            <h5>LANA WINTERS</h5>
                            <div className="comment-date">
                              <a href="#">
                                <i className="pe-7s-clock"></i>JANUARY 15, 2015
                              </a>
                            </div>
                            <div className="comment-reply">
                              <a className="comment-reply-link" href="#">
                                Reply
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>
                      <ul className="children">
                        <li className="comment">
                          <article className="comment-body media">
                            <div className="media-left">
                              <a href="#">
                                <img
                                  className="avatar"
                                  src="assets/images/cmt-avatar-3.jpg"
                                  alt="img"
                                />
                              </a>
                            </div>
                            <div className="media-body">
                              <div className="comment-content">
                                Class aptent taciti sociosqu ad litora torquent
                                per conubia nostra, per inceptos himenaeos.
                                Nulla fermentum libero libero, ac mollis nisl
                                commodo in.
                              </div>
                              <div className="comment-info">
                                <h5>JOHNNY CLAMP</h5>
                                <div className="comment-date">
                                  <a href="#">
                                    <i className="pe-7s-clock"></i>JANUARY 15,
                                    2015
                                  </a>
                                </div>
                                <div className="comment-reply">
                                  <a className="comment-reply-link" href="#">
                                    Reply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </article>
                        </li>
                        <li className="comment">
                          <article className="comment-body media">
                            <div className="media-left">
                              <a href="#">
                                <img
                                  className="avatar"
                                  src="assets/images/cmt-avatar-2.jpg"
                                  alt="img"
                                />
                              </a>
                            </div>
                            <div className="media-body">
                              <div className="comment-content">
                                Nulla mattis mi quis elit porttitor gravida.
                              </div>
                              <div className="comment-info">
                                <h5>LANA WINTERS</h5>
                                <div className="comment-date">
                                  <a href="#">
                                    <i className="pe-7s-clock"></i>JANUARY 15,
                                    2015
                                  </a>
                                </div>
                                <div className="comment-reply">
                                  <a className="comment-reply-link" href="#">
                                    Reply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </article>
                        </li>
                      </ul>
                    </li>
                    <li className="comment">
                      <article className="comment-body media">
                        <div className="media-left">
                          <a href="#">
                            <img
                              className="avatar"
                              src="assets/images/cmt-avatar-4.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <div className="comment-content">
                            Phasellus finibus neque in elementum sagittis.
                            Integer congue cursus ullamcorper. Pellentesque a
                            metus pulvinar, varius nisl porta, cursus nibh.
                            Praesent ut dapibus nisl, ut faucibus sem. Maecenas
                            non congue ipsum. Interdum et malesuada fames ac
                            ante ipsum primis in faucibus. Etiam vitae vehicula
                            justo, at tincidunt nulla.
                          </div>
                          <div className="comment-info">
                            <h5>ANNA PANDORA</h5>
                            <div className="comment-date">
                              <a href="#">
                                <i className="pe-7s-clock"></i>JANUARY 15, 2015
                              </a>
                            </div>
                            <div className="comment-reply">
                              <a className="comment-reply-link" href="#">
                                Reply
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>
                    </li>
                  </ol>
                  <div className="comment-respond pe-7s-user">
                    <form action="#" className="comment-form">
                      <div className="wrap-rating">
                        <h4>YOUR RATING: </h4>
                        <span
                          className="review-star star-rating"
                          data-score="0"
                        ></span>
                      </div>
                      <div className="wrap-textarea">
                        <textarea
                          className="textarea-form"
                          placeholder="YOUR REVIEW*"
                        ></textarea>
                      </div>
                      <input
                        className="input-form"
                        type="text"
                        placeholder="YOUR NAME*"
                      />
                      <input
                        className="input-form"
                        type="email"
                        placeholder="YOUR EMAIL*"
                      />
                      <button type="submit">ADD REVIEW</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </div>

          {/* <div className="related-products">
          <h3>RELATED PRODUCTS</h3>
          <div className="box-content">
            <div className="showcase">
              <div className="row">
                <div className="box-product">
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item has-deal">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-9.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-9.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="countdown" data-time="2015/09/06"></div>
                        <div className="product-new">NEW</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">UTILITY JACKET</a>
                      </h4>
                      <p className="product-price">189.99 USD</p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-10.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-10.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="product-sale">-25%</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">HOODED BOMBER JACKET</a>
                      </h4>
                      <p className="product-price">
                        <ins>
                          <span className="amount">100.99 USD</span>
                        </ins>
                        <del>
                          <span className="amount">189.99 USD</span>
                        </del>
                      </p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-11.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-11.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="product-sale">-25%</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">
                          STRUCTURED COLLARLESS COAT
                        </a>
                      </h4>
                      <p className="product-price">
                        <ins>
                          <span className="amount">100.99 USD</span>
                        </ins>
                        <del>
                          <span className="amount">189.99 USD</span>
                        </del>
                      </p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-12.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-12.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="product-sale">-25%</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">
                          COAT WITH WRAPAROUND COLLAR
                        </a>
                      </h4>
                      <p className="product-price">
                        <ins>
                          <span className="amount">100.99 USD</span>
                        </ins>
                        <del>
                          <span className="amount">189.99 USD</span>
                        </del>
                      </p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="showcase">
              <div className="row">
                <div className="box-product">
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-2.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-2-thumb.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="product-sale">-25%</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">
                          COAT WITH WOOL WRAPAROUND COLLAR
                        </a>
                      </h4>
                      <p className="product-price">
                        <ins>
                          <span className="amount">100.99 USD</span>
                        </ins>
                        <del>
                          <span className="amount">189.99 USD</span>
                        </del>
                      </p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-1.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-1-thumb.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="product-sale">-25%</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">HANDMADE FLARED JACKET</a>
                      </h4>
                      <p className="product-price">
                        <ins>
                          <span className="amount">100.99 USD</span>
                        </ins>
                        <del>
                          <span className="amount">189.99 USD</span>
                        </del>
                      </p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item has-deal">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-3.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-3-thumb.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="countdown" data-time="2015/09/06"></div>
                        <div className="product-new">NEW</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">UTILITY JACKET</a>
                      </h4>
                      <p className="product-price">189.99 USD</p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="product-item">
                      <div className="product-thumb">
                        <div className="main-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-3.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="overlay-img">
                          <a href="single-product.html">
                            <img
                              className="img-responsive"
                              src="assets/images/product-img-3-thumb.jpg"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="product-sale">-25%</div>
                        <a href="single-product.html" className="details">
                          <i className="pe-7s-search"></i>
                        </a>
                      </div>
                      <h4 className="product-name">
                        <a href="single-product.html">UTILITY JACKET</a>
                      </h4>
                      <p className="product-price">
                        <ins>
                          <span className="amount">100.99 USD</span>
                        </ins>
                        <del>
                          <span className="amount">189.99 USD</span>
                        </del>
                      </p>
                      <div className="group-buttons">
                        <button
                          type="button"
                          className="add-to-cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Cart"
                        >
                          <span>Add to Cart</span>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare this Product"
                        >
                          <i className="pe-7s-repeat"></i>
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Wishlist"
                        >
                          <i className="pe-7s-like"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav">
            <span className="prev">
              <i className="fa fa-angle-left"></i>
            </span>
            <span className="next">
              <i className="fa fa-angle-right"></i>
            </span>
          </div>
        </div> */}
        </div>
      
      </div>
    </div>
  );
};

export default ProductDetail;

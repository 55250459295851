const Shipping = () => {
  return (
    <div className="our-team-content">
      <div className="header-our-team">
        <div className="container">
          <h1>SHIPPING & DELIVERY POLICY</h1>
        </div>
      </div>

      <hr className="gray-line" />
      <div className="the-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div className="team-item">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>SHIPPING POLICIES</label>
                    </h4>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>DOMESTIC</label>
                    </h4>
                    Shipping is free in India Orders are shipped within 5 to 6
                    working days. Unless otherwise specifically mentioned in the
                    product details. Delivery of all orders will be duly done to
                    the address as mentioned by you at the time of placing the
                    order. In case of modifications, kindly drop us a mail at
                    support@thewovenwonders.com within 2 hours of placing the
                    order.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>INTERNATIONAL</label>
                    </h4>
                    Shipping charges for our international customers will be
                    calculated during check out depending on the order value and
                    dimensions of your order
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>
                        Any custom charges or VAT has to be borne by the
                        customer
                      </label>
                    </h4>
                    Our delivery partner is Delhivery and Ship Rocket.
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      <label>
                        From 1st July 2021, VAT would be applicable on all
                        shipments delivered in EU. The duty and VAT has to be
                        borne by the customers.{" "}
                      </label>
                    </h4>
                    Orders are delivered within 7-8 working days. Unless
                    otherwise specifically mentioned in the product details.
                    Delivery of all orders will be duly done to the address as
                    mentioned by you at the time of placing the order. In case
                    of modifications, kindly drop us a mail at
                    support@thewovenwonders.com within 2 hours, of placing
                    order.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;

import { createSlice } from "@reduxjs/toolkit";
import { getProductList, postProductList } from "../../../actions/productAction";
import { PRODUCT_DETAIL_BY_ID, PRODUCT_LIST } from "../../../constTypes";

const initialState = {
    status : "idle",
    fields:[
            {
                field: "Product_Code",
                headerName: "Product Code",
                width: 100,
                align: 'left',
                editable: true,
              },
              {
                field: "Product_Name",
                headerName: "Product Name",
                width: 100,
                editable: true,
              },
              {
                field: "Display_Name",
                headerName: "Display Name",
                width: 100,
                editable: true,
              },
              {
                field: "Purchase_Price",
                headerName: "Purchase Price",
                width: 100,
              },
              {
                field : "Sale_Price",
                headerName : "Sale Price",
                width: 40,
              },
              {
                field: "Vendor",
                headerName: "Vendor",
                width: 100,
                align: 'left',
                editable: true,
              },
              {
                field: "Product_Category",
                headerName: "Product Category",
                width: 100,
              },
              {
                field: "Quantity",
                headerName: "Quantity",
                width: 100,
                editable: true,
              },
              {
                field: "Description",
                headerName: "Description",
                width: 100,
              },
              {
                field : "Product_Tags",
                headerName : "Product Tags",
                width: 40,
              },
              {
                field : "actions",
                headerName : "Actions",
                width: 40,
              },
        ],
        productList: [], 
        categories:{
          categoryList : [
              "Cotton", "Silk","Linen"
          ],
      },
      tags : ["Luxe","Hot","Silk","Linen","Featured"]
};

const productAttributeSlice = createSlice(
    {
        name:"productAttributeSlice",
        initialState,
        reducers:{

        },
        // extraReducers : {
        //     [getProductList.pending]: (state, action) => {
        //       state.status = "Loading...";
        //     //   console.log(state.status);
        //     },
        //     [getProductList.fulfilled]: (state, action) => {
        //     //   console.log("Success...");
        //       state.status = "Success...";
        //       state.productList = action.payload;
        //     },
        //     [getProductList.rejected]: (state, action) => {
        //       state.status = "Rejected...";
        //       state.error = action.error.message;
        //     },
        //     [postProductList.pending]: (state, action) => {
        //       state.status = "Loading...";
        //     },
        //     [postProductList.fulfilled]: (state, action) => {
        //       state.status = "Updated...";
        //     },
        //     [postProductList.rejected]: (state, action) => {
        //       state.status = "Rejected...";
        //       state.error = action.error.message;
        //     },
        //   }
    }
)

export const product_list = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const get_product_detail_by_id = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_DETAIL_BY_ID:
      return action.payload || [];
    default:
      return state;
  }
};


export default productAttributeSlice.reducer;
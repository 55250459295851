/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  productCategoryAction,
  productAction,
  cartAction,
  customerAction
} from "../../store/actions";
import { keys } from "../../config/keys";
import { Link, useLocation, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { get_category_by_id } from "../../store/actions/productCategoryAction";
import Sidebar from "../Sidebar";

const ProductListingSearch = () => {
  const dispatch = useDispatch<any>();
  const { categoryId } = useParams();
  const location = useLocation();
  //   const getCategoryId = categoryId || 0;
  const product_list = useSelector((obj: any) => obj.product_list);
  const category_by_id = useSelector((obj: any) => obj.category_by_id);
  const customer = useSelector((obj: any) => obj.customer);

  const [productList, setProductList] = useState<any>([]);
  const _get_product_list = () => dispatch(productAction.get_product_list());
  
  const _get_category_details = () => dispatch(get_category_by_id(categoryId));

  useEffect(() => {
    _get_product_list();
    //_get_product_detail();
  }, []);

  useEffect(() => {
    // if (Number(categoryId) > 0) {
    //   _get_category_details();
    // }
    // if (Number(categoryId) > 0) {
    //   setProductList([
    //     ...(product_list &&
    //       product_list.filter(
    //         (cateogryObj: any) =>
    //           Number(cateogryObj.categoryid) === Number(categoryId)
    //       ))
    //   ]);
    // } else {
    //   setProductList([...product_list]);
    // }


    const filtered = product_list.filter((x:any)=>x.label.toLowerCase().includes(location?.state?.toLowerCase()));
    setProductList([...filtered]);

  }, [categoryId, product_list,location?.state]);

  const addCart = (item: any) => {
    const { attribute_values, ...others } = item;
    let p = {
      ...others,
      productid: others.id,
      quantity: 1
    };
    dispatch(cartAction.addCartItem(p));
  };

  const productSort = (e: any) => {
    // console.log(e.target.value)
    if (Number(e.target.value) === 0) {
      setProductList([...productList]);
    }
    else if (Number(e.target.value) === 1) {
      const t = productList.sort(
        (a: any, b: any) =>
          a.online_sales_price_with_tax - b.online_sales_price_with_tax
      );
      setProductList([...t]);
    }
    else if (Number(e.target.value) === 2) {
      const t = productList.sort(
        (a: any, b: any) =>
          b.online_sales_price_with_tax - a.online_sales_price_with_tax
      );
      setProductList([...t]);
    }
    
  };
  // useEffect(() => {
  //   _get_product_list();
  //   //_get_category_list();
  // }, []);

  // useEffect(() => {
  //   if (product_list.length > 0) {
  //     const t = product_list[0].filter(
  //       (cateogryObj: any) =>
  //         Number(cateogryObj.categoryid) ===
  //         (Number(categoryId) > 0 ? Number(categoryId) : cateogryObj.categoryid)
  //     );

  //     console.log(t);
  //     if (Number(categoryId) > 0) {
  //       setProductList([
  //         ...(product_list[0] &&
  //           product_list[0].filter(
  //             (cateogryObj: any) =>
  //               Number(cateogryObj.categoryid) === Number(categoryId)
  //           ))
  //       ]);
  //     } else {
  //       setProductList([...product_list[0]]);
  //     }
  //   }
  // }, [product_list]);

  return (
    <div className="main">
      <div className="container">
        <div
          className="header-page"
          // style={{
          //   background: `url(${keys.ServicePath}/${category_by_id.categoryimage})`
          // }}
        >
          <h1>
            {Number(categoryId) > 0
              ? `${category_by_id?.category || "SHOP"}`
              : `SHOP`}
          </h1>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Sidebar data={product_list || []} />
          </div>
          <div className="col-md-9">
            <div className="main-content">
              <div className="top-products">
                <div className="showing-results">
                  {`SHOWING ${productList.length} RESULTS`}
                </div>
                <div className="sortby">
                  <select className="custom-select-1" onChange={productSort}>
                    <option value="0">DEFAULT SORTING</option>
                    <option value="1">Price: Lowest First</option>
                    <option value="2">Price: Highest First</option>
                    {/* <option value="3">Best seller</option> */}
                  </select>
                </div>
              </div>
              <div className="box-product row">
                {productList.length > 0 &&
                  productList.map((obj: any, index: number) => {
                    let imgList = obj.product_images;

                    let thumbImage =
                      imgList &&
                      imgList.filter(
                        (thumbImageObj: any) => thumbImageObj.isthumbnail === 1
                      );

                    // let mainImage =
                    //   thumbImage.length > 0 &&
                    //   imgList.filter(
                    //     (mainImageObj: any) =>
                    //       mainImageObj.id !== thumbImage[0].id
                    //   );

                    let mainImage = imgList.filter(
                      (x: any) => x.isdefault === 1
                    );
                    if (mainImage.length <= 0 && imgList.length > 0) {
                      mainImage = [imgList[0]];
                    }
                    if (imgList.length >= 2) {
                      thumbImage = [imgList[1]];
                    }

                    return (
                      <div className="col-md-4 col-sm-6" key={index}>
                        <div className="product-item">
                          <div className="product-thumb">
                            {Number(obj.stock) <= 0 && (
                              <div className="product-lable">
                                <div
                                  className={`outofstock`}
                                >{`OUT OF STOCK`}</div>
                              </div>
                            )}
                            <div className="main-img">
                              <Link to={`/productdetail/${obj.id}`}>
                                {mainImage && mainImage.length > 0 ? (
                                  // <img
                                  //   className="img-responsive"
                                  //   style={{
                                  //     width: "250px",
                                  //     margin: "auto",
                                  //     height: "350px"
                                  //   }}
                                  //   src={`${keys.ServicePath}/${mainImage[0].filepath}`}
                                  //   alt="img"
                                  // />
                                  <LazyLoadImage
                                    src={`${keys.ServicePath}/${mainImage[0].filepath}`}
                                    width={250}
                                    height={350}
                                    style={{
                                      width: "250px",
                                      margin: "auto",
                                      minHeight: "350px",
                                      height: "350px"
                                    }}
                                    effect="blur"
                                    className="img-responsive"
                                    alt="TWW"
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={require("../../assets/images/noImage.jpg")}
                                    width={250}
                                    height={350}
                                    style={{
                                      width: "250px",
                                      margin: "auto",
                                      minHeight: "350px",
                                      height: "350px"
                                    }}
                                    effect="blur"
                                    className="img-responsive"
                                    alt="TWW"
                                  />
                                )}
                              </Link>
                            </div>
                            <div className="overlay-img">
                              <Link to={`/productdetail/${obj.id}`}>
                                {thumbImage && thumbImage.length > 0 ? (
                                  // <img
                                  //   className="img-responsive"
                                  //   style={{
                                  //     width: "250px",
                                  //     margin: "auto",
                                  //     height: "350px"
                                  //   }}
                                  //   src={`${keys.ServicePath}/${thumbImage[0].filepath}`}
                                  //   alt="img"
                                  // />
                                  <LazyLoadImage
                                    src={`${keys.ServicePath}/${thumbImage[0].filepath}`}
                                    width={250}
                                    height={350}
                                    style={{
                                      width: "250px",
                                      margin: "auto",
                                      height: "350px"
                                    }}
                                    effect="black-and-white"
                                    className="img-responsive"
                                    alt="TWW"
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={require("../../assets/images/noImage.jpg")}
                                    width={250}
                                    height={350}
                                    style={{
                                      width: "250px",
                                      margin: "auto",
                                      minHeight: "350px",
                                      height: "350px"
                                    }}
                                    effect="blur"
                                    className="img-responsive"
                                    alt="TWW"
                                  />
                                )}
                              </Link>
                            </div>
                            <div className="d-flex product-button">
                              <Link
                                to={`/productdetail/${obj.id}`}
                                className="details"
                              >
                                <i className="pe-7s-search"></i>
                              </Link>

                              {Number(obj.stock) > 0 && (
                                <button
                                  type="button"
                                  className="details"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Add to Cart"
                                  onClick={() => addCart(obj)}
                                >
                                  <i className="pe-7s-cart"></i>
                                  {/* <span>Add to Cart</span> */}
                                </button>
                              )}
                              {/* <button
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                className="me-2 details"
                                title="Compare this Product"
                              >
                                <i className="pe-7s-repeat"></i>
                              </button> */}
                              <button
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add to Wishlist"
                                className="details"
                                onClick={()=>dispatch(customerAction.add_wishlist({customerid: customer?.id,productid: obj.id}))}
                              >
                                <i className="pe-7s-like"></i>
                              </button>
                            </div>
                            {/* <Link
                              to={`/productdetail/${obj.id}`}
                              className="details"
                            >
                              <i className="pe-7s-search"></i>
                            </Link> */}
                          </div>
                          <h4 className="product-name">
                            <Link
                              to={`/productdetail/${obj.id}`}
                              className="details"
                            >
                              {obj.productname}
                            </Link>
                          </h4>
                          <p className="product-price">
                            {keys.currencyFormat(
                              obj.online_sales_price_with_tax
                            )}
                          </p>
                          {/* <div className="group-buttons">
                            {Number(obj.stock) > 0 ? (
                              <button
                                type="button"
                                className="add-to-cart"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add to Cart"
                                onClick={() => addCart(obj)}
                              >
                                <span>Add to Cart</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="add-to-cart"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add to Cart"
                                disabled
                              >
                                <span>OUT OF STOCK</span>
                              </button>
                            )}
                            <button
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              className="me-2"
                              title="Compare this Product"
                            >
                              <i className="pe-7s-repeat"></i>
                            </button>
                            <button
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add to Wishlist"
                            >
                              <i className="pe-7s-like"></i>
                            </button>
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* <nav className="pagination">
                                    <ul className="page-numbers">
                                        <li><a href="#">1</a></li>
                                        <li><span className="current">2</span></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#">5</a></li>
                                        <li><a href="#">6</a></li>
                                        <li><a className="last " href="#"><i className="fa fa-angle-double-right"></i></a></li>
                                    </ul>
                                </nav> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListingSearch;

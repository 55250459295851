import { useDispatch, useSelector } from "react-redux";
import { blogAction } from "../../store/actions";
import { useEffect } from "react";
//import "../../assets/ckeditor.css";
import { keys } from "../../config/keys";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { Link } from "react-router-dom";

const Blogs = () => {
  const dispatch = useDispatch<any>();
  const blogs_list = useSelector((obj: any) => obj.blogs);

  const _get_blogs = () => dispatch(blogAction.get_blogs());

  useEffect(() => {
    _get_blogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main">
      <div className="container">
        <div className="header-page">
          <h1>Blogs</h1>
        </div>
        <div className="main-content">
          <div className="row g-2">
            {blogs_list?.map((x: any) => {
              return (
                <div className="col-md-4 col-lg-4 col-sm-12">
                  {/* <div className="card">
                    <div dangerouslySetInnerHTML={{ __html: x.description }} />
                  </div> */}
                  <div
                    className="card"
                    style={{ width: "100%", minHeight: 320, maxHeight: 320 }}
                  >
                    <img
                      src={`${keys.ServicePath}/${x.descriptionimage}`}
                      className="card-img-top"
                      alt="tww_image"
                    />
                    <div className="card-body">
                      <Link to={`/blogs/${x.id}`}>
                        <h5>{x.title}</h5>
                      </Link>

                      {/* <div
                        dangerouslySetInnerHTML={{ __html: x.description }}
                      /> */}
                      {/* <div className="d-flex justify-content-between">
                        <blockquote className="blockquote mb-0">
                          <footer className="blockquote-footer">
                            <cite title="Source Title">{x.createdon}</cite>
                          </footer>
                        </blockquote>
                      </div> */}
                    </div>
                    <div className="card-footer ">
                      <div className="d-flex justify-content-between align-items-center">
                        <span
                          className="text-body-tertiary fst-italic"
                          style={{ fontSize: "x-small" }}
                        >
                          {x.createdon}
                        </span>
                        <ul className="list-social-2">
                          <li>
                            <FacebookShareButton
                              url={`${keys.authorizedURL}/blogs/${x.id}`}
                            >
                              <FacebookIcon size={24} borderRadius={5} />
                            </FacebookShareButton>
                          </li>
                          <li>
                            <TwitterShareButton
                              url={`${keys.authorizedURL}/blogs/${x.id}`}
                            >
                              <TwitterIcon size={24} borderRadius={5} />
                            </TwitterShareButton>
                          </li>
                          {/* <li>
                      <InstapaperShareButton url={`${keys.authorizedURL}/productdetail/${productId}`}>
                          <InstapaperIcon size={36} borderRadius={5} />
                          </InstapaperShareButton>
                      
                      </li> */}
                          <li>
                            <WhatsappShareButton
                              url={`${keys.authorizedURL}/blogs/${x.id}`}
                            >
                              <WhatsappIcon size={24} borderRadius={5} />
                            </WhatsappShareButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
